import { Fragment } from "react";
import { Link } from "react-router-dom";

import SideNav from "../components/SideNav";
import pic1 from "../images/bloogers.png";
import pic2 from "../images/voicefest.png";
import pic3 from "../images/eshower.png";
import "./ServicesPage.css";

const TestIntellectualProperty = () => {
  const DMLink = "/services/intellectual";

  const listItem = [
    { name: "Bloggers Mela", link: `${DMLink}/bloggers-mela` },
    { name: "The Voice Fest", link: `${DMLink}/voice-fest` },
    { name: "Business E-Shower", link: `${DMLink}/business-shower` },
  ];

  return (
    <Fragment>
      <div className="service-container">
        <h1>Intellectual Properties</h1>
        <div className="service-cards-container">
          <Link to={`${DMLink}/bloggers-mela`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic1} alt="Image 1" />
              <h2>Bloggers Mela</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/voice-fest`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic2} alt="Image 1" />
              <h2>The Voice Fest</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/business-shower`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic3} alt="Image 1" />
              <h2>Business E-Shower</h2>
            </div>
          </Link>
        </div>
      </div>

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default TestIntellectualProperty;
