import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { API_17, API_18, API_2, API_3 } from "../api/api";

import "./ModelForm.css";

export default function ModalForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [town, setTown] = useState("");
  const [number, setNumber] = useState("");
  const [insta, setInsta] = useState("");

  const navigate = useNavigate(); // Define navigate using useNavigate

  const checkout = async (amount) => {
    localStorage.setItem("amount", amount); // Set the 'amount' in localStorage\
    try {
      if (number.length > 9) {
        var data1 = await fetch(API_17, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount, name, email, town }),
        });

        var keys = await fetch(API_2, {
          method: "GET",
        });
        keys = await keys.json();
        // console.log(keys, "yes");
        data1 = await data1.json();
      } else {
        alert("Please Enter Valid Number");
      }
      if (data1.success) {
        const options = {
          key: keys.key, // Enter the Key ID generated from the Dashboard
          amount: data1.order.tot, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Atraski Fashion Designer Registration", //your business name
          description: "",
          // image: "https://example.com/your_logo",
          order_id: data1.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: API_3,
          handler: function(response) {
            // Handle the payment success callback here
            // console.log("Payment successful: ", response);
            try {
              navigate("/");
            } catch (error) {
              console.error("Navigation error:", error);
            }
            // You can navigate to a success page or perform further actions here

            // Save data to the database (you need to implement this on your backend)
            saveDataToDatabase(response);
          },
          // prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          //     name: "Gaurav Kumar", //your customer's name
          //     email: "gaurav.kumar@example.com",
          //     contact: "9000090000" //Provide the customer's phone number for better conversion rates
          // },

          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#ffc0cb",
          },
        };
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.success", function(response) {
          // Payment was successful, now save data to the database
          saveDataToDatabase();
          console.log("Payment successful:", response);
          // You can navigate to a success page or perform further actions here
        });

        rzp1.open();
      } else {
        // console.error("Error creating Razorpay order:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  const saveDataToDatabase = async (resp) => {
    try {
      const paisa = localStorage.getItem("amount");

      // Send a request to your server to save data to the database
      const response = await fetch(API_18, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          insta,
          town,
          number,
          amount: paisa,
          razorpay_order_id: resp.razorpay_order_id,
          razorpay_payment_id: resp.razorpay_payment_id,
        }),
      });

      const data = await response.json();

      if (data.success) {
        console.log("Data saved successfully:", data);
        // You can show a success message to the user
      } else {
        console.error("Error saving data:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <div>
      <h1 style={{ color: "#fc0c8c", textAlign: "center" }}>
        Elite Membership Form
      </h1>
      <div className="membership-container">
        <div className="membership-form-description">
          <span
            style={{
              color: "white",
              fontSize: "1.2rem",
            }}
          >
            Unlock the door to a world of exclusive benefits with our tailored
            membership plans. Whether you're a beginner or a seasoned
            enthusiast, we have the perfect plan to enhance your experience. By
            becoming a member, you'll gain access to a range of premium services
            designed to bring value, convenience, and exclusive opportunities
            directly to you.
          </span>
          <br />
          <ul
            style={{
              fontSize: "1.1rem",
              padding: 0,
            }}
          >
            <li style={{ marginBottom: "10px" }}>
              Offering three collaborative brand shoots, unlock new
              opportunities for creativity and exposure, and watch yourself soar
              to new heights.
            </li>
            <li style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}> Priority offers:</span>{" "}
              Whether it's barter collaborations or paid projects, we prioritize
              our members, ensuring you get first dibs on exciting opportunities
              that come our way.
            </li>
            <li style={{ marginBottom: "10px" }}>
              Get featured on our website and showcase yourself to our global
              audience.
            </li>
            <li style={{ marginBottom: "10px" }}>
              Time to time featuring on our social media profiles.
            </li>
            <li style={{ marginBottom: "10px" }}>
              Experience the VIP treatment with our membership perks – enjoy
              complimentary passes for dine-in experiences, exclusive club
              visits, and curated events.
            </li>
            <li>
              Unlock access to our closed fashion and community network
              activities with our membership. As a member, you'll join a
              tight-knit community of industry professionals, influencers, and
              tastemakers who share your passion for fashion and creativity.
            </li>
          </ul>
        </div>
      </div>
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            checkout(499);
          }}
          style={{ color: "white" }}
        >
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Enter your name"
              required
            />
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter email"
              required
            />
          </Form.Group>

          <Form.Group controlId="checkIn">
            <Form.Label>Town/City</Form.Label>
            <Form.Control
              type="text"
              name="checkIn"
              value={town}
              onChange={(e) => {
                setTown(e.target.value);
              }}
              placeholder="Enter City/Town"
              required
            />
          </Form.Group>

          <Form.Group controlId="insta">
            <Form.Label>Instagram Link</Form.Label>
            <Form.Control
              type="text"
              name="insta"
              placeholder="Instagram URL"
              value={insta}
              onChange={(e) => {
                setInsta(e.target.value);
              }}
              required
            />
          </Form.Group>

          <Form.Group controlId="checkOut">
            <Form.Label>Mobile(whatsapp)</Form.Label>
            <Form.Control
              type="text"
              name="checkOut"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              placeholder="Enter Mobile number"
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    </div>
  );
}
