import PageBuilder from "../../utility/PageBuilder";

const YouthMarketing = () => {
  const content = [
    "Youth marketing is a dynamic and impactful strategy focused on engaging the younger demographic, typically those between the ages of 13 to 35. At Atraski, we specialize in creating advertising campaigns, products, and experiences that resonate with the interests, values, and lifestyles of young people. Understanding that this audience is constantly evolving, we craft innovative marketing solutions that capture their attention and foster lasting connections.",
    "Our youth marketing approach leverages the power of social media, influencers, and trending events to build relationships with the youth audience. Platforms like Instagram, TikTok, and YouTube are integral to our strategies, allowing us to reach young people where they spend most of their time. By collaborating with popular influencers and content creators, we ensure that your brand is represented authentically and engagingly, enhancing its appeal and credibility among young consumers.",
    "In addition to social media, Atraski's youth marketing campaigns often incorporate experiential marketing and event-based strategies. We create memorable experiences that not only showcase your brand but also encourage active participation and engagement from the youth. Whether it’s through pop-up events, interactive digital campaigns, or exclusive product launches, we make sure your brand stands out in a crowded marketplace and leaves a lasting impression.",
    "Atraski’s expertise in youth marketing helps brands tap into the vibrant and influential youth market effectively. By understanding the nuances of youth culture and staying ahead of trends, we develop campaigns that are not only relevant but also impactful. Let Atraski be your partner in navigating the dynamic world of youth marketing, driving growth, and building a strong, loyal customer base among the younger generation.",
  ];

  return <PageBuilder title="Campaign / Youth Marketing" content={content} />;
};

export default YouthMarketing;
