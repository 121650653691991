import { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import "bootstrap/dist/css/bootstrap.css";

import About from "./components/About";
import HomePage from "./pages/HomePage";

import Careers from "./components/Careers";
import Event1 from "./components/PublicRelations";
import ContactUs from "./components/contact";
import Public from "./components/publicreln";
import Web from "./components/web";
import Mice from "./components/mice";
import Marketing from "./components/InfluentialMarketing";
import Prod from "./components/production";
import Blogger from "./components/Blogger";
import Eshower from "./components/Eshower";

import Privacy1 from "./components/Privacy1";
import Chat from "./components/chat";
import M from "./components/Models";
import EventsAtAtraski from "./components/EventsAtAtraski";
import Services from "./components/Services";
import Section1 from "./components/section1";
import GoogleForm from "./components/Form";
import BrandCollaboration from "./components/Collaboration";
import Cities from "./components/Cities";
import Main2 from "./pages/HomePage";
import BloggerMelaDelhi from "./components/BloggersMelaDelhi";
import Cartview from "./components/Cartview";
import PaymentForm from "./components/PaymentForm";
import ProductPage from "./components/Productpage";
import ProductViewPage from "./components/ProductViewPage";
import Fash from "./components/fash";
import Intellectual from "./components/Intellectual";
import EventExhibition from "./components/EventExhibition";
import Paymentform2 from "./components/Paymentform2";
import ModalForm from "./components/ModalForm";
import FashionRegistration from "./components/fashionRegistration";
import Fahionattendies from "./components/fahionattendies";
import FashionDesigner from "./components/FashionDesigner";
import ExperiencedDesigner from "./components/ExperiencedDesigner";
import FashionForm from "./components/FashionForm";
import PlaceLogo from "./components/PlaceLogo";
import SponsorsRegistration from "./components/SponsorsRegistration";
import BloggerForm from "./components/BloggerForm";
import Footer from "./components/Footer";
import ScrollRestoration from "./utility/ScrollRestoration";

// Digital Marketing
import DigitalMarketing from "./components/DigitalMarketing";
import SocialMediaManagerPage from "./pages/DigitalMarketing/SocialMediaManagerPage";
import RunningAdsPage from "./pages/DigitalMarketing/RunningAdsPage";
import SEOPage from "./pages/DigitalMarketing/SEOPage";

// Public Relations
import PublicRelations from "./components/PublicRelations";
import MediaOutreachPage from "./pages/PublicRelations/MediaOutreachPage";

// Influential marketing
import InfluentialMarketing from "./components/InfluentialMarketing";
import InfluencersCampaign from "./pages/InfluentialMarketing/InfluencersCampaign";
import CollegeAmbassador from "./pages/InfluentialMarketing/CollegeAmbassador";
import YouthMarketing from "./pages/InfluentialMarketing/YouthMarketingPage";

// Intellectual Property
import BloggersMela from "./pages/IntellectualProperties/BloggersMelaPage";
import VoiceFest from "./pages/IntellectualProperties/VoiceFestPage";
import BusinessShower from "./pages/IntellectualProperties/BusinessShowerPage";

// Events and Exhibition
import DealersMeet from "./pages/EventExhibition/DealersMeetPage";
import StallFabrication from "./pages/EventExhibition/StallFabricationPage";
import LaunchEvent from "./pages/EventExhibition/LaunchEventPage";
import AtlBtlBranding from "./pages/EventExhibition/AtlBtlBrandingPage";

// Work Page
import WorkPage from "./pages/WorkPage";

import TestDigitalMarketingPage from "./pages/TestDigitalMarketingPage";
import TestPublicRelation from "./pages/TestPublicRealtionPage";
import TestInfluentialMarketing from "./pages/TestInfluentialMarketingPage";
import TestIntellectualProperty from "./pages/TestIntellectualPage";
import TestEventsExhibition from "./pages/TestEventsExhibitionPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollRestoration />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/services">
             
            <Route path="digital-marketing" element={<DigitalMarketing />} />
            <Route
              path="digital-marketing/social-media-manager"
              element={<SocialMediaManagerPage />}
            />
            <Route
              path="digital-marketing/running-ads"
              element={<RunningAdsPage />}
            />
            <Route path="digital-marketing/seo" element={<SEOPage />} />

             
            <Route path="public-relations" element={<PublicRelations />} />
            <Route
              path="public-relations/media-outreach"
              element={<MediaOutreachPage />}
            />

            
            <Route
              path="influential-marketing"
              element={<InfluentialMarketing />}
            />
            <Route
              path="influential-marketing/influencers-campaign"
              element={<InfluencersCampaign />}
            />
            <Route
              path="influential-marketing/college-ambassador"
              element={<CollegeAmbassador />}
            />
            <Route
              path="influential-marketing/youth-marketing"
              element={<YouthMarketing />}
            />

             
            <Route path="intellectual" element={<Intellectual />} />
            <Route
              path="intellectual/bloggers-mela"
              element={<BloggersMela />}
            />
            <Route path="intellectual/voice-fest" element={<VoiceFest />} />
            <Route
              path="intellectual/business-shower"
              element={<BusinessShower />}
            />

            
            <Route path="event&exhibition" element={<EventExhibition />} />
            <Route
              path="event&exhibition/dealers-meet"
              element={<DealersMeet />}
            />
            <Route
              path="event&exhibition/stall-fabrication"
              element={<StallFabrication />}
            />
            <Route
              path="event&exhibition/launch-event"
              element={<LaunchEvent />}
            />
            <Route
              path="event&exhibition/atl-btl-branding"
              element={<AtlBtlBranding />}
            />
          </Route> */}
          {/* <Route path="/OurWork" element={<Work1 />} /> */}
          <Route path="/OurWork" element={<WorkPage />} />

          {/* Services */}
          <Route path="/services">
            {/* Digital Marketing */}
            <Route
              path="digital-marketing"
              element={<TestDigitalMarketingPage />}
            />
            <Route
              path="digital-marketing/social-media-manager"
              element={<SocialMediaManagerPage />}
            />
            <Route
              path="digital-marketing/running-ads"
              element={<RunningAdsPage />}
            />
            <Route path="digital-marketing/seo" element={<SEOPage />} />

            {/* Public Relations */}
            <Route path="public-relation" element={<TestPublicRelation />} />
            <Route
              path="public-relations/media-outreach"
              element={<MediaOutreachPage />}
            />

            {/* Influential Marketing */}
            <Route
              path="influential-marketing"
              element={<TestInfluentialMarketing />}
            />
            <Route
              path="influential-marketing/influencers-campaign"
              element={<InfluencersCampaign />}
            />
            <Route
              path="influential-marketing/college-ambassador"
              element={<CollegeAmbassador />}
            />
            <Route
              path="influential-marketing/youth-marketing"
              element={<YouthMarketing />}
            />

            {/* Intellectual Properties */}
            <Route
              path="intellectual-property"
              element={<TestIntellectualProperty />}
            />
            <Route
              path="intellectual/bloggers-mela"
              element={<BloggersMela />}
            />
            <Route path="intellectual/voice-fest" element={<VoiceFest />} />
            <Route
              path="intellectual/business-shower"
              element={<BusinessShower />}
            />

            {/* Events & Exhibition */}
            <Route
              path="events-exhibition"
              element={<TestEventsExhibition />}
            />
            <Route
              path="event&exhibition/dealers-meet"
              element={<DealersMeet />}
            />
            <Route
              path="event&exhibition/stall-fabrication"
              element={<StallFabrication />}
            />
            <Route
              path="event&exhibition/launch-event"
              element={<LaunchEvent />}
            />
            <Route
              path="event&exhibition/atl-btl-branding"
              element={<AtlBtlBranding />}
            />
          </Route>

          <Route path="/careers" element={<Careers />} />
          <Route path="/public" element={<Public />} />
          <Route path="/production" element={<Prod />} />
          <Route path="/mice" element={<Mice />} />
          {/* <Route path="/web" element={<Web />} /> */}
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/blogger" element={<Blogger />} />
          <Route path="/Eshower" element={<Eshower />} />
          {/* <Route path="/footer" element={<Footer1 />} /> */}
          <Route path="/privacy-policy" element={<Privacy1 />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/mainmodel" element={<M />} />
          <Route path="/eventatatraski" element={<EventsAtAtraski />} />
          <Route path="/collabration" element={<BrandCollaboration />} />
          <Route path="/servicesfashion" element={<Services />} />
          <Route path="/cities" element={<Cities />} />
          <Route path="/fashion" element={<Section1 />} />
          <Route path="/googleform" element={<GoogleForm />} />
          <Route path="/bloggersMelaDelhi" element={<BloggerMelaDelhi />} />
          <Route path="/Cartview" element={<Cartview />} />
          <Route path="/paymentform" element={<PaymentForm />} />
          <Route path="/blogger1/:id" element={<ProductPage />} />
          <Route path="/productviewpage" element={<ProductViewPage />} />
          <Route path="/form2" element={<Paymentform2 />} />
          <Route path="/modelform" element={<ModalForm />} />
          <Route
            path="/fashionregistration"
            element={<FashionRegistration />}
          />
          <Route path="/fashionattendees" element={<Fahionattendies />} />
          <Route path="/fashiondesigner" element={<FashionDesigner />} />
          <Route
            path="/ModelHuntForDelhiFashionConfluence"
            element={<FashionForm />}
          />
          <Route
            path="/experiencedDesigner"
            element={<ExperiencedDesigner />}
          />
          <Route path="/placelogo" element={<PlaceLogo />} />
          <Route
            path="/sponsorsRegistration"
            element={<SponsorsRegistration />}
          />
          <Route path="/bloggersmela-mumbai" element={<BloggerForm />} />
        </Routes>
        {/* Footer */}
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
