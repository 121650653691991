import React, { useState } from "react";
import { Form, Button, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [town, setTown] = useState("");
  const [number, setNumber] = useState("");

  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.Cartreducer.carts);

  const [quantities, setQuantities] = useState(() => {
    const savedQuantities =
      JSON.parse(localStorage.getItem("cartQuantities")) || {};
    const initialQuantities = {};
    cartItems.forEach((item) => {
      initialQuantities[item.id] = savedQuantities[item.id] || 1;
    });
    return initialQuantities;
  });

  const CalculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const quantity = quantities[item.id] || 0;
      const subtotal = Math.round(quantity * item.price);
      total += subtotal;
    });
    return total;
  };
  const totalval = CalculateTotal();

  const checkout = async (amount) => {
    localStorage.setItem("amount", amount);
    try {
      if (number.length > 9) {
        var data1 = await fetch("https://www.api.atraski.com/Order", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount, name, email, town, number }),
        });

        var keys = await fetch("https://www.api.atraski.com/key", {
          method: "GET",
        });
        keys = await keys.json();
        data1 = await data1.json();

        console.log(keys);
        console.log(data1.amount);
        console.log(data1.id);
        console.log(data1);

        if (data1.success) {
          const options = {
            key: keys.key,
            amount: data1.order.tot,
            currency: "INR",
            name: "Bloggers Mela",
            description: "Test Transaction",
            order_id: data1.order.id,
            callback_url: "https://www.api.atraski.com/verification",
            handler: function(response) {
              console.log("Payment successful: ", response);
              try {
                navigate("/");
              } catch (error) {
                console.error("Navigation error:", error);
              }
            },
            prefill: {
              name: "",
              email: "",
              contact: "",
            },
            notes: {
              address: "Razorpay Corporate Office",
            },
            theme: {
              color: "#fc0c8c",
            },
          };
          const rzp1 = new window.Razorpay(options);
          rzp1.on("payment.success", function(response) {
            console.log("Payment successful: ", response);
          });
          rzp1.open();
        } else {
          console.error("Error creating Razorpay order:", data1.error);
        }
      } else {
        alert("Please Enter Valid Number");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  const saveDataToDatabase = async () => {
    try {
      const paisa = localStorage.getItem("amount");
      const response = await fetch(
        "https://www.api.atraski.com/saveDataToDatabase",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            town,
            number,
            amount: paisa,
            quantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("Data saved successfully:", data);
      } else {
        console.error("Error saving data:", data.error);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  return (
    <Container className="py-5" style={{ width: "70vh" }}>
      <h3 className="mb-3 text-black">Fill Your Details</h3>
      <Row>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            checkout(totalval);
          }}
        >
          <Form.Group controlId="name" className="formms">
            <Form.Label className="text-black">Name</Form.Label>
            <Form.Control
              style={{ border: "1px solid black" }}
              type="text"
              name="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Enter your name"
              className="mb-3"
              required
            />
          </Form.Group>
          <Form.Group controlId="email" className="formms">
            <Form.Label className="text-black">Email address</Form.Label>
            <Form.Control
              style={{ border: "1px solid black" }}
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Enter email"
              className="mb-3"
              required
            />
          </Form.Group>
          <Form.Group controlId="checkIn" className="formms">
            <Form.Label className="text-black">Town/City</Form.Label>
            <Form.Control
              style={{ border: "1px solid black" }}
              type="text"
              name="checkIn"
              value={town}
              onChange={(e) => {
                setTown(e.target.value);
              }}
              className="mb-3"
              required
            />
          </Form.Group>
          <Form.Group controlId="checkOut" className="formms">
            <Form.Label className="text-black">Mobile(whatsapp)</Form.Label>
            <Form.Control
              style={{ border: "1px solid black" }}
              type="text"
              name="checkOut"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              className="mb-3"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Row>
    </Container>
  );
};

export default PaymentForm;
