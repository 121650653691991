import PageBuilder from "../../utility/PageBuilder";

const BusinessShower = () => {
  const content = [
    "Welcome to Business E-Shower, a spectacular virtual gathering designed to celebrate and support new businesses. Hosted by Atraski, this event brings together entrepreneurs, industry leaders, and like-minded professionals from across the business landscape. It’s an opportunity to forge meaningful connections, share valuable insights, and inspire growth in a dynamic and engaging environment. With Business E-Shower, you can experience the convenience of connecting with a vibrant community from the comfort of your own screen.",
    "At Business E-Shower, we believe in the power of collaboration and idea exchange. Our event features a range of interactive sessions, including keynote speeches from industry experts, panel discussions, and networking opportunities. These sessions are designed to provide attendees with practical advice, innovative strategies, and inspiring stories that can help propel their businesses forward. Whether you're looking to expand your network, gain new perspectives, or find inspiration for your next big idea, Business E - Shower has something for everyone.",
    "One of the key highlights of Business E-Shower is the ability to connect with like-minded individuals who share your drive and ambition. Whether you're a seasoned entrepreneur looking to broaden your horizons or a budding business owner seeking guidance and mentorship, this event offers a unique platform to build relationships and exchange ideas. Our virtual format ensures that you can participate from anywhere, making it easier than ever to engage with a global community of business professionals.",
    "Join us at Business E-Shower and immerse yourself in a celebration of entrepreneurship and innovation. At Atraski, we are committed to fostering a supportive and inspiring environment for businesses to thrive. Don’t miss this opportunity to connect, learn, and grow with some of the brightest minds in the business world.",
  ];

  return <PageBuilder title="Business E-Shower" content={content} />;
};

export default BusinessShower;
