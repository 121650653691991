import kolkata from "./imagegallery/Kolkata (2).jpg";

import Hyderabad from "./imagegallery/Hyderabad (2).jpg";
import pune from "./imagegallery/Pune (2).jpg";
import silliguri from "./imagegallery/Siliguri (2).jpg";
import Delhi from "./imagegallery/Kolkata (2).jpg";
import Guhawti from "./imagegallery/Guwahati (2).jpg";
import Mumbai from "./imagegallery/Mumbai (2).jpg";
import Jaipur from "./imagegallery/Jaipur (2).jpeg";
// import Jaipur  from "./imagegallery/Jaipur (2).jpeg";
import bloggermelaproduct from "../images/newblog.jpeg";

// export const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 1024 },
//     items: 5,
//     slidesToSlide: 2,
//   },
//   desktop: {
//     breakpoint: { max: 1024, min: 800 },
//     items: 4,
//   },
//   tablet: {
//     breakpoint: { max: 800, min: 464 },
//     items: 2,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

export const productData = [
  {
    id: 1,
    imageurl: bloggermelaproduct,
    name: "Bloggers Mela Delhi",
    price: 150,
    description: "Lets enjoy this event blogger sMela ",
  },
  // {
  //   id: 2,
  //   imageurl: Mumbai,
  //   name: "Mumbai",
  //   price: 21.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 3,
  //   imageurl: Hyderabad,
  //   name: "Hyderabad",
  //   price: 99.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 4,
  //   imageurl: pune,
  //   name: "Pune",
  //   price: 14.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 5,
  //   imageurl: silliguri,
  //   name: "silliguri",
  //   price: 38.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 6,
  //   imageurl: Delhi,
  //   name: "Delhi",
  //   price: 149.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 7,
  //   imageurl: Guhawti,
  //   name: "Guhawti",
  //   price: 38.99,
  //   description: "Some text about the product..",
  // },
  // {
  //   id: 8,
  //   imageurl:Jaipur,
  //   name: "Jaipur",
  //   price: 49.99,
  //   description: "Some text about the product..",
  // },
];
