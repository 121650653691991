import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

import client2 from "../images/brand2.png";
import client4 from "../images/brand3.png";
import client5 from "../images/brand4.jpg";
import client6 from "../images/brand5.png";
import client7 from "../images/brand6.jpg";
import client8 from "../images/brand7.png";
import client9 from "../images/brand9.png";
import client10 from "../images/brand10.png";
import client11 from "../images/brand11.png";
import client12 from "../images/brand12.jpg";
import banner from "../images/digital-marketing-banner.jpg";
import buzz from "../images/AT Buzz Logo copy.png";
import event from "../images/AT Events Logo.png";
import mcmv from "../images/mcmv logo.png";
import inspiring from "../images/atraski logo new -wm.png";
import fashion from "../images/AT Fashion.png";
import travel from "../images/AT Travel new 300- 150.png";

import "./About.css";

const About = () => {
  const [init, setInit] = useState(false);

  const styles = {
    heading: {
      textAlign: "center",
      color: "#fff",
      fontSize: "32px",
      fontWeight: "bold",

      marginTop: "-35vh",
    },
    paragraph: {
      fontSize: "25px",
    },
  };

  styles.paragraph["@media (max-width: 768px)"] = {
    fontSize: "20px",
  };

  styles.paragraph["@media (max-width: 480px)"] = {
    fontSize: "18px",
  };

  // Particles.js
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: "#000000",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#fc0c8c",
        },
        links: {
          color: "#ffffff",
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 6,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return (
    <>
      {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      )}
      <div
        style={{
          marginTop: "-1rem",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div className="d-flex mt-5 mb-5 flex-column">
          <div className="firstpart">
            <h1
              style={{ fontSize: "30px", color: "#fc0c8c", fontWeight: "900" }}
            >
              ABOUT US
            </h1>
            <p
              className="heading-1 params"
              style={{ fontSize: "18px", width: "80%", lineHeight: "40px" }}
            >
              At Atraski, we believe in the power of authentic connections and
              the ability of compelling storytelling to elevate brands. Our team
              of seasoned professionals brings a wealth of experience and a
              fresh perspective to every project. Whether you're a startup
              looking to establish your online presence or an established brand
              seeking a marketing revamp, Atraski is here to tailor solutions
              that resonate with your audience and drive measurable results. Our
              commitment to excellence, creativity, and strategic thinking sets
              us apart, making Atraski the go-to choice for businesses seeking
              to make a lasting impact in the market .
            </p>
          </div>

          <div className="mt-5 mb-5">
            <div className="whoarewe mt-5">
              <h1
                style={{
                  fontSize: "30px",
                  color: "#fc0c8c",
                  fontWeight: "900",
                }}
                className="mb-5"
              >
                OUR SEGMENTS
              </h1>
              <p
                className="heading-1 params"
                style={{ fontSize: "18px", width: "80%", lineHeight: "40px" }}
              >
                At Atraski, we believe in the power of authentic connections and
                the ability of compelling storytelling to elevate brands. Our
                team of seasoned professionals brings a wealth of experience and
                a fresh perspective to every project. Whether you're a startup
                looking to establish your online presence or an established
                brand seeking a marketing revamp, Atraski is here to tailor
                solutions that resonate with your audience and drive measurable
                results. Our commitment to excellence, creativity, and strategic
                thinking sets us apart, making Atraski the go-to choice for
                businesses seeking to make a lasting impact in the market.
              </p>
              <div
                className="mb-5"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5rem",
                }}
              >
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "70px" }}>
                    <img src={inspiring} width="150px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    We are a marketing performance company with the mission to
                    add value to the brands we work with.
                  </span>
                </div>
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "70px" }}>
                    <img src={event} width="150px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    We are an event management company with a vision to impact
                    your product launches and conferences.
                  </span>
                </div>
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "80px" }}>
                    <img src={fashion} width="150px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    We are a fashion management company providing wider avenues
                    and expanding your brand's horizons.
                  </span>
                </div>
              </div>
              <div
                className="mt-5"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "70px" }}>
                    <img src={mcmv} width="250px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    A dynamic company hosting open mic events for artists to
                    showcase talent and connect with the community.
                  </span>
                </div>
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "80px" }}>
                    <img src={buzz} width="150px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    We provide an integrated solution for influencer marketing
                    needs, with a promise of brand upscaling.
                  </span>
                </div>
                <div
                  className="section1"
                  style={{
                    width: "250px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  <div style={{ height: "80px" }}>
                    <img src={travel} width="150px" />
                  </div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    We are coming up with a product as AtStay.in to simplify B2C
                    booking for the offbeat stays in India.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="caraousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <h1 className="text-white text-center" style={{ margin: "50px" }}>
            Our Clients
          </h1>
          <div className="carousel-item active">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img
                  src={client2}
                  className="img-fluid"
                  alt="Client 1"
                  style={{ fontSize: "20px" }}
                />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client4} className="img-fluid" alt="Client 2" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client5} className="img-fluid" alt="Client 3" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client6} className="img-fluid" alt="Client 4" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client7} className="img-fluid" alt="Client 5" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client8} className="img-fluid" alt="Client 6" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client9} className="img-fluid" alt="Client 7" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client10} className="img-fluid" alt="Client 8" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client11} className="img-fluid" alt="Client 9" />
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="row justify-content-center">
              <div className="col-md-2">
                <img src={client12} className="img-fluid" alt="Client 10" />
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#clientSlider"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#clientSlider"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default About;
