import { Fragment } from "react";
import { Link } from "react-router-dom";

import SideNav from "../components/SideNav";
import pic1 from "../images/socialmediamarketing.jpg";
import pic2 from "../images/socialmediaadvertising.jpg";
import pic3 from "../images/seo.jpg";
import "./ServicesPage.css";

const TestDigitalMarketingPage = () => {
  const DMLink = "/services/digital-marketing";

  const listItem = [
    { name: "Social Media Manager", link: `${DMLink}/social-media-manager` },
    { name: "Running Ads", link: `${DMLink}/running-ads` },
    { name: "Off Page SEO And On Page SEO", link: `${DMLink}/seo` },
  ];

  return (
    <Fragment>
      <div className="service-container">
        <h1>Digital Marketing</h1>
        <div className="service-cards-container">
          <Link to={`${DMLink}/social-media-manager`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic1} alt="Image 1" />
              <h2>Social Media Manager</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/running-ads`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic2} alt="Image 1" />
              <h2>Running Ads</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/seo`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic3} alt="Image 1" />
              <h2>Off Page SEO and On Page SEO</h2>
            </div>
          </Link>
        </div>
      </div>

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default TestDigitalMarketingPage;
