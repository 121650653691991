import React, { Fragment, useRef } from "react";
import { useState } from "react";

import emailjs from "emailjs-com";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
// import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import "./contact.css";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ur7cevn",
        "template_s7jko4d",
        form.current,
        "VBOwlrdUCvw7Qk5Nz"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    window.alert("Message submitted successfully!");
  };

  const officeLocations = [
    { lat: 28.5169764, lng: 77.1956774 }, // Office 1
    { lat: 22.6387857, lng: 88.4148439 }, // Office 2
  ];

  return (
    <Fragment>
      <h1>Contact Us</h1>
      <div className="contact-us-container">
        <div className="contact-form">
          <form onSubmit={sendEmail}>
            <label htmlFor="name">Name</label>
            <input
              id="name"
              className="name"
              type="text"
              placeholder="Please enter your name..."
              required
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              className="email"
              type="email"
              placeholder="Please enter your email..."
              required
            />
            <label>What do you have in mind?</label>
            <textarea cols={5} placeholder="Please enter query..." />

            <button type="submit">Submit</button>
          </form>
        </div>

        <div className="map-container">
          <iframe src="https://www.google.com/maps/d/u/2/embed?mid=1ISvbUz1D_ElV7fboDRqeYPfKnceg8vU&z=5&ehbc=2E312F&noprof=1"></iframe>
          {/* <iframe
            className="map-embed"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.155085941714!2d77.24649377562001!3d28.535057875718103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3b268970a15%3A0xc14659a35592332b!2so%2C%208%2C%20Block%20B%2C%20Chittaranjan%20Park%2C%20New%20Delhi%2C%20Delhi%20110019!5e0!3m2!1sen!2sin!4v1685513201117!5m2!1sen!2sin&markers=color:red%7Clabel:1%7C28.5169764,77.1956774&markers=color:blue%7Clabel:2%7C22.6387857,88.4148439&zoom=10
  "
            // height="300vh"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe> */}
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
