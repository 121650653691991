import PageBuilder from "../../utility/PageBuilder";

const LaunchEvent = () => {
  const content = [
    "Make a lasting impression with Atraski's expertly crafted launch events. Whether it's a product, service, or brand launch, we specialize in creating unforgettable experiences that generate buzz and excitement. Our team works closely with you to understand your objectives and brand identity, ensuring that every aspect of the event is tailored to reflect your vision. From innovative themes and stunning visuals to seamless execution and media coverage, we go above and beyond to ensure that your launch event captures attention and leaves a lasting impression.",
    "At Atraski, we understand that a successful launch event is more than just a celebration; it's an opportunity to make a statement and establish your brand in the minds of your audience. That's why we approach each launch with creativity, enthusiasm, and attention to detail, ensuring that every element of the event is designed to engage and excite. From venue selection and decor to entertainment and guest experiences, we handle everything with precision and care, so you can focus on showcasing your brand in the best possible light.",
    "Our launch events are not just about creating a buzz; they're about creating meaningful connections with your audience. We believe that the key to a successful launch is to create an immersive experience that resonates with your target market. That's why we work closely with you to develop a comprehensive event strategy that aligns with your brand values and marketing objectives. Whether you're looking to introduce a new product, service, or brand, Atraski's launch events are designed to captivate, inspire, and leave a lasting impression on your audience.",
    "Trust Atraski to make your next launch event a resounding success that sets the stage for your brand's success. With our innovative approach, attention to detail, and commitment to excellence, we'll help you create a launch event that not only meets but exceeds your expectations. Let us take care of the details while you focus on showcasing your brand to the world.",
  ];

  return <PageBuilder title="Launch Event" content={content} />;
};

export default LaunchEvent;
