import { Fragment } from "react";
import { Link } from "react-router-dom";

import SideNav from "../components/SideNav";
import pic1 from "../images/mediaoutreach.png";
import "./ServicesPage.css";

const TestPublicRelation = () => {
  const DMLink = "/services/public-relations";

  const listItem = [
    { name: "Media Outreach", link: `${DMLink}/media-outreach` },
  ];

  return (
    <Fragment>
      <div className="service-container">
        <h1>Public Relations</h1>
        <div className="service-cards-container">
          <Link to={`${DMLink}/media-outreach`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic1} style={{ width: "20rem" }} />
              <h2>Media Outreach</h2>
            </div>
          </Link>
        </div>
      </div>

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default TestPublicRelation;
