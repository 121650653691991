import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
// import logo4 from "../images/logo.png";
import logo4 from "../images/AtraskiLogo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [isNavbarTransparent, setIsNavbarTransparent] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false);
  const [hamburgerIsFocus, setHamburgerIsFocus] = useState(false);

  // const isServicesPage = location.pathname.split("/")[1] === "services";

  const handleNavClick = () => {
    setIsNavOpen(false);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    setHamburgerIsOpen((hamburgerIsOpen) => !hamburgerIsOpen);
  };

  const subMenuRef = useRef(null);
  const subSubMenuRef = useRef(null);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const entry = entries[0];
      setIsNavbarTransparent(!entry.isIntersecting);
      setIsSticky(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(handleIntersection);
    observer.observe(navbarRef.current);

    return () => {
      observer.unobserve(navbarRef.current);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        subMenuRef.current &&
        !subMenuRef.current.contains(event.target) &&
        subSubMenuRef.current &&
        !subSubMenuRef.current.contains(event.target)
      ) {
        closeDropdowns();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const closeDropdowns = () => {
    setSubMenuOpen(false);
  };

  return (
    <nav
      ref={navbarRef}
      className={`navbar navbar-expand-lg navbar-dark  ${
        isSticky ? "sticky" : ""
      } ${isNavOpen ? "show" : ""}`}
      style={{
        // position: "sticky",
        top: "0",
        zIndex: "1000",
        padding: "0 40px 0 28px",
      }}
    >
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img
            src={logo4}
            alt="Your Logo"
            // width="200px"
            className="d-inline-block align-text-top logo"
            // style={{ border: "blue 1px solid" }}
          />
        </NavLink>

        <button
          className={`hamburger ${hamburgerIsOpen ? "open" : ""}`}
          type="button"
          onClick={toggleNav}
        >
          <span className="hamburger-top"></span>
          <span className="hamburger-middle"></span>
          <span className="hamburger-bottom"></span>
        </button>
        <div
          className={`collapse navbar-collapse justify-content-end ${
            isNavOpen ? "show" : ""
          }`}
          id="navbarNav"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Home
              </NavLink>
            </li>

            {/* <li
              className={`nav-item dropdown ${subMenuOpen ? "show" : ""}`}
              ref={subMenuRef}
            >
              <NavLink
                className="navbar-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                role="button"
                onClick={toggleSubMenu}
                onMouseEnter={serviceLinkMouseEnterHandler}
                onMouseLeave={serviceLinkMouseLeaveHandler}
                style={
                  subMenuOpen || isServicesPage ? { color: "#fc0c8c" } : {}
                }
              >
                Services
              </NavLink>

              <ul
                className={`dropdown-menu ${subMenuOpen ? "show" : ""}`}
                onMouseEnter={subMenuMouseEnterHandler}
                onMouseLeave={subMenuMouseLeaveHandler}
                aria-labelledby="navbarDropdownMenuLink"
                style={{ width: "250px", padding: "20px 0" }}
              >
                <div className="service-links-container">
                  <li>
                    <NavLink
                      className="service-link"
                      to="/services/digital-marketing"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Digital Marketing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="service-link"
                      to="/services/public-relations"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Public Relation
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="service-link"
                      to="/services/influential-marketing"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Influential Marketing
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="service-link"
                      to="https://mycitymyvoice.in/"
                      target="_blank"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      My City My Voice
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="service-link"
                      to="https://atstay.in/"
                      target="_blank"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Travel
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="service-link"
                      to="/fashion"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Fashion
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="service-link"
                      to="services/intellectual"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Intellectual Properties
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="service-link"
                      to="services/event&exhibition"
                      onClick={() => {
                        closeDropdowns();
                        handleNavClick();
                      }}
                      style={({ isActive }) =>
                        isActive ? { color: "#fc0c8c" } : {}
                      }
                    >
                      Events & Exhibition
                    </NavLink>
                  </li>
                </div>
              </ul>
            </li> */}

            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/about"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                About Us
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/OurWork"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Work
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/careers"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Careers
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="https://mycitymyvoice.in/"
                onClick={handleNavClick}
              >
                MCMV
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="https://atstay.in/"
                onClick={handleNavClick}
              >
                Atstay
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/fashion"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Fashion
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/blogger"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Blogger's Mela
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/Eshower"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                E-Shower
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/careers"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Careers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/ContactUs"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                Contact us
              </NavLink>
            </li> */}

            {/* <li className="nav-item">
              <NavLink
                className="navbar-link"
                to="/Cartview"
                onClick={handleNavClick}
                style={({ isActive }) => (isActive ? { color: "#fc0c8c" } : {})}
              >
                <i className="fa-solid fa-cart-shopping"></i>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
