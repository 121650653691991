import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { API_20, API_21, API_2, API_3 } from "../api/api";

// Styling in index.css

export default function BloggerForm() {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [insta, setInsta] = useState("");
  const [followersRange, setFollowersRange] = useState("");
  const [city, setCity] = useState("");
  const [guestTypeRadio, setGuestTypeRadio] = useState("");
  const [influenceRadio, setInfluenceRadio] = useState("");
  const [smallVideoRadio, setSmallVideoRadio] = useState("");

  const navigate = useNavigate(); // Define navigate using useNavigate

  const checkout = async (amount) => {
    localStorage.setItem("amount", amount); // Set the 'amount' in localStorage
    try {
      if (contact.length > 9) {
        var data1 = await fetch(API_20, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount, name, email }),
        });

        var keys = await fetch(API_2, {
          method: "GET",
        });
        keys = await keys.json();
        // console.log(keys, "yes");
        data1 = await data1.json();
      } else {
        alert("Please Enter Valid Number");
      }
      if (data1.success) {
        const options = {
          key: keys.key, // Enter the Key ID generated from the Dashboard
          amount: data1.order.tot, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Bloggers Mela Mumbai", //your business name
          description: "",
          // image: "https://example.com/your_logo",
          order_id: data1.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: API_3,
          handler: function(response) {
            // Handle the payment success callback here
            // console.log("Payment successful: ", response);
            try {
              navigate("/");
            } catch (error) {
              console.error("Navigation error:", error);
            }
            // You can navigate to a success page or perform further actions here

            // Save data to the database (you need to implement this on your backend)
            saveDataToDatabase(response);
          },
          // prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone contact
          //     name: "Gaurav Kumar", //your customer's name
          //     email: "gaurav.kumar@example.com",
          //     contact: "9000090000" //Provide the customer's phone contact for better conversion rates
          // },

          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#ffc0cb",
          },
        };
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.success", function(response) {
          // Payment was successful, now save data to the database
          saveDataToDatabase();
          console.log("Payment successful:", response);
          // You can navigate to a success page or perform further actions here
        });

        rzp1.open();
      } else {
        // console.error("Error creating Razorpay order:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  const saveDataToDatabase = async (resp) => {
    try {
      const amount = localStorage.getItem("amount");

      // Send a request to your server to save data to the database
      const response = await fetch(API_21, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          contact,
          email,
          insta,
          followersRange,
          city,
          guestType: guestTypeRadio,
          influence: influenceRadio,
          smallVideo: smallVideoRadio,
          amount,
          razorpay_order_id: resp.razorpay_order_id,
          razorpay_payment_id: resp.razorpay_payment_id,
        }),
      });

      const data = await response.json();

      if (data.success) {
        console.log("Data saved successfully:", data);
        // You can show a success message to the user
      } else {
        console.error("Error saving data:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <div>
      <Container>
        <h1 className="bloggers-form-heading">Blogger's Mela Mumbai</h1>
        <h2 className="bloggers-form-heading-2">
          Registration For Mumbai's Bloggers Mela
        </h2>
        <main className="bloggers-form-container">
          <div className="bloggers-form-content-container">
            Bloggers Mela is a unique concept that converges brands and bloggers
            in a vibrant celebration. <br />
            <br />
            Bloggers Mela orchestrates a captivating symphony of elements,
            including insightful panel discussions, trendy pop stalls, dazzling
            fashion shows, enchanting music, open mic sessions, and mesmerising
            face artistry, among other attractions.
            <br />
            <br />
            Reasons to be part of this exciting event:
            <ol style={{ margin: "1rem 0 1rem 0" }}>
              <li>
                Forge direct connections with prominent national-level bloggers.
              </li>
              <li>
                Revel in the privileges reserved for our esteemed ambassadors.
              </li>
              <li>
                Unwind in the exclusive lounge area, designed for your comfort.
              </li>
              <li>
                Bask in the limelight as we feature you on our dynamic social
                media platforms.
              </li>

              <li>
                Seize the opportunity to actively participate in the captivating
                Bloggers Mela event.
              </li>
              <li>
                Engage in post-event activities that keep the momentum going.
              </li>
            </ol>
          </div>

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              checkout(99);
            }}
            style={{ color: "white" }}
            className="bloggers-form"
          >
            <Form.Group controlId="name">
              <Form.Label>
                Name <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Enter your name"
                required
              />
            </Form.Group>

            <Form.Group controlId="contact">
              <Form.Label>
                Contact Number <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="contact"
                value={contact}
                onChange={(e) => {
                  setContact(e.target.value);
                }}
                placeholder="Enter contact number"
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="checkOut">
            <Form.Label>Alternate Contact Number</Form.Label>
            <Form.Control
              type="text"
              name="alternateContact"
              value={alternateContact}
              onChange={(e) => {
                setAlternateContact(e.target.value);
              }}
              placeholder="Enter alternate contact number"
            />
          </Form.Group> */}

            <Form.Group controlId="email">
              <Form.Label>
                Email Id <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Enter email"
                required
              />
            </Form.Group>

            <Form.Group controlId="insta">
              <Form.Label>
                Instagram Link <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="insta"
                placeholder="Insta Url"
                value={insta}
                onChange={(e) => {
                  setInsta(e.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group controlId="follow-range">
              <Form.Label>
                Followers Range <span className="required">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="followersRange"
                placeholder="Enter Range of Followers"
                value={followersRange}
                onChange={(e) => {
                  setFollowersRange(e.target.value);
                }}
                required
              />
            </Form.Group>

            <Form.Group controlId="city">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="Enter your city"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Form.Group>

            {/* <Form.Group controlId="insta">
            <Form.Label>
              Facebook Link <span className="required">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="facebook"
              placeholder="Facebook URL"
              value={facebook}
              onChange={(e) => {
                setFacebook(e.target.value);
              }}
              required
            />
          </Form.Group> */}

            {/* <Form.Group controlId="checkIn">
            <Form.Label>LinkedIn Profile Link (If you have one )</Form.Label>
            <Form.Control
              type="text"
              name="linkedIn"
              value={linkedIn}
              onChange={(e) => {
                setLinkedIn(e.target.value);
              }}
              placeholder="LinkedIn URL"
            />
          </Form.Group>
          <Form.Group controlId="contentGenre" className="content-genre-group">
            <Form.Label>
              Content Genre <span className="required">*</span>
            </Form.Label>
            <div className="content-genre-container">
              {[
                "Fashion",
                "Food",
                "Lifestyle",
                "Youtuber",
                "Beauty",
                "Travel",
                "Education",
              ].map((genreOption) => (
                <Form.Check
                  key={genreOption}
                  type="radio"
                  name="contentGenre"
                  id={`contentGenre-${genreOption}`}
                  label={genreOption}
                  value={genreOption}
                  checked={genre === genreOption}
                  onChange={(e) => setGenre(e.target.value)}
                  required
                />
              ))}
            </div>
          </Form.Group> */}

            <Form.Group controlId="guestType" className="radio-group mb-30">
              <Form.Label>
                Are you a? <span className="required">*</span>
              </Form.Label>
              <div className="radio-container">
                {["Attendee", "Blogger"].map((guestTypeOption) => (
                  <Form.Check
                    key={guestTypeOption}
                    type="radio"
                    name="guestTypeRadio"
                    id={`guestTypeRadio-${guestTypeOption}`}
                    label={guestTypeOption}
                    value={guestTypeOption}
                    onChange={(e) => setGuestTypeRadio(e.target.value)}
                    required
                  />
                ))}
              </div>
            </Form.Group>
            <Form.Group controlId="influence" className="radio-group mb-30">
              <Form.Label>
                Can you influence people among your circle to be a part of the
                Bloggers Mela? <span className="required">*</span>
              </Form.Label>
              <div className="radio-container">
                {["Yes", "No"].map((influenceOption) => (
                  <Form.Check
                    key={influenceOption}
                    type="radio"
                    name="influenceRadio"
                    id={`influenceRadio-${influenceOption}`}
                    label={influenceOption}
                    value={influenceOption}
                    onChange={(e) => setInfluenceRadio(e.target.value)}
                    required
                  />
                ))}
              </div>
            </Form.Group>
            {/* <Form.Group controlId="smallVideo" className="radio-group mb-30">
              <Form.Label>
                Are comfortable in creating small bytes or small videos related
                to the event? <span className="required">*</span>
              </Form.Label>
              <div className="radio-container">
                {["Yes", "Yessssss"].map((smallVideoOption) => (
                  <Form.Check
                    key={smallVideoOption}
                    type="radio"
                    name="smallVideoRadio"
                    id={`smallVideoRadio-${smallVideoOption}`}
                    label={smallVideoOption}
                    value={smallVideoOption}
                    onChange={(e) => setSmallVideoRadio(e.target.value)}
                    required
                  />
                ))}
              </div>
            </Form.Group> */}

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </main>
      </Container>
    </div>
  );
}
