import React, { useState } from "react";
import { Form, Button, Container, Row } from "react-bootstrap";
import "./Paymentform.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { API_2, API_3, API_8 } from "../api/api";

const Paymentform2 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Town, setTown] = useState("");
  const [Number, setNumber] = useState("");
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.Cartreducer.carts);

  // Calculate Total
  const CalculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total;
  };

  const totalval = CalculateTotal();

  const checkout = async (amount) => {
    localStorage.setItem("amount", amount);
    try {
      if (Number.length > 9) {
        var data1 = await fetch(API_8, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount, name, email, Town, Number }),
        });

        var keys = await fetch(API_2, {
          method: "GET",
        });

        keys = await keys.json();
        data1 = await data1.json();

        console.log(keys);
        console.log(data1.amount);
        console.log(data1.id);
        console.log(data1);

        if (data1.success) {
          const options = {
            key: keys.key,
            amount: data1.order.tot,
            currency: "INR",
            name: "At Stay Meet Up",
            description: "Test Transaction",
            order_id: data1.order.id,
            callback_url: API_3,
            theme: { color: "#66cccc" },
          };

          const rzp1 = new window.Razorpay(options);

          rzp1.on("payment.success", function(response) {
            console.log("Payment successful: ", response);
            try {
              navigate("/");
            } catch (error) {
              console.error("Navigation error:", error);
            }
          });

          rzp1.open();
        } else {
          console.error("Error creating Razorpay order:", data1.error);
        }
      } else {
        alert("Please Enter Valid Number");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    }
  };

  return (
    <div className="bg-black mm">
      <div style={{ backgroundColor: "#eee" }}>
        <Container className="py-5" style={{ width: "70vh" }}>
          <h3 className="mb-3 text-black">At Stay Meet Up</h3>
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                checkout(totalval);
              }}
            >
              <Form.Group controlId="name">
                <Form.Label className="text-black">Name</Form.Label>
                <Form.Control
                  style={{ border: "1px solid black" }}
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter your name"
                  className="mb-3"
                  required
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label className="text-black">Email address</Form.Label>
                <Form.Control
                  style={{ border: "1px solid black" }}
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Enter email"
                  className="mb-3"
                  required
                />
              </Form.Group>

              <Form.Group controlId="checkIn">
                <Form.Label className="text-black">Town/City</Form.Label>
                <Form.Control
                  style={{ border: "1px solid black" }}
                  type="text"
                  name="checkIn"
                  value={Town}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                  className="mb-3"
                  required
                />
              </Form.Group>

              <Form.Group controlId="checkOut">
                <Form.Label className="text-black">Mobile(whatsapp)</Form.Label>
                <Form.Control
                  style={{ border: "1px solid black" }}
                  type="text"
                  name="checkOut"
                  value={Number}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  className="mb-3"
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Paymentform2;
