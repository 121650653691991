import PageBuilder from "../../utility/PageBuilder";

const VoiceFest = () => {
  const content = [
    "Welcome to The Voice Fest, the ultimate celebration of vocal artistry brought to you by Atraski. Immerse yourself in a world of captivating performances, interactive workshops, and a platform designed to let your talent shine. Whether you're a singer, storyteller, voice actor, or simply an enthusiast, The Voice Fest has something extraordinary in store for you. This event is more than just a gathering; it's an experience that celebrates the beauty, power, and versatility of the human voice.",
    "At The Voice Fest, we believe that every voice has a unique story to tell. Our event features a diverse lineup of performances from talented artists across various genres and styles. From soulful singing to compelling storytelling and dynamic voice acting, you'll be inspired by the creativity and skill on display. Our stages provide a welcoming environment for performers to share their passion and connect with an appreciative audience.",
    "In addition to the mesmerizing performances, The Voice Fest offers a range of interactive workshops and sessions designed to enhance your vocal abilities. Led by industry professionals and seasoned artists, these workshops cover techniques, tips, and insights to help you refine your craft. Whether you're looking to improve your singing technique, explore voice acting, or develop your storytelling skills, our workshops provide valuable learning opportunities in a supportive setting.",
    "The Voice Fest is more than an event; it's a celebration of the artistry and talent that lies within each of us. It's a place where you can connect with fellow enthusiasts, share your experiences, and grow as an artist. Join us at The Voice Fest and be part of an unforgettable experience that celebrates the richness and diversity of vocal expression. At Atraski, we're dedicated to creating platforms that empower and inspire, and The Voice Fest is a testament to our commitment to nurturing talent and creativity.",
  ];

  return <PageBuilder title="The Voice Fest" content={content} />;
};

export default VoiceFest;
