/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/heading-has-content */
// import event from './imagegallery/event.jpg'
import subimg1 from "./imagegallery/dealer.png";
import subimg2 from "./imagegallery/stall.png";
import subimg3 from "./imagegallery/launch.png";
import subimg4 from "./imagegallery/mediaoutreach.png";

// import eventimg1 from './imagegallery/w1.jpeg'
// import eventimg2 from './imagegallery/w2.jpeg'
// import eventimg3 from './imagegallery/w3.jpeg'
// import eventimg4 from './imagegallery/w4.jpeg'
import eventimg5 from "./imagegallery/w5.jpeg";
import e1 from "../images/e15.jpg";
import e2 from "../images/e16.png";
import e3 from "../images/e17.png";
import e4 from "../images/e18.png";
import audi from "../images/audi.jpg";

import portfolio1 from "../images/e1.jpg";
import portfolio2 from "../images/e2.jpg";
import portfolio3 from "../images/e3.jpg";
import portfolio4 from "../images/e4.jpg";
import portfolio5 from "../images/e5.jpg";
import portfolio6 from "../images/e6.jpg";
import portfolio7 from "../images/e7.jpg";

import React, { useState } from "react";
import { Container, Modal, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../index.css";

function PublicRelations() {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalClose2 = () => {
    setShowModal2(false);
  };

  const handleModalClose3 = () => {
    setShowModal3(false);
  };

  const handleModalClose4 = () => {
    setShowModal4(false);
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleImageClick2 = () => {
    setShowModal2(true);
  };

  const handleImageClick3 = () => {
    setShowModal3(true);
  };

  const handleImageClick4 = () => {
    setShowModal4(true);
  };

  return (
    <>
      <div className="container">
        <h1 className="text-center mb-3 font-weight-bold">Public Relation</h1>
      </div>
      <Container style={{ marginBottom: "5rem" }}>
        <h4 className="mt-3 text-white text-center">What We Offer</h4>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col md={4} className="my-4">
            <Link to="media-outreach">
              <div
                className="image-container"
                onClick={handleImageClick4}
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={subimg4}
                  alt="Image 3"
                  className="img-fluid"
                  style={{ width: "500px", alignItems: "center" }}
                />
                <h2
                  className="image-heading"
                  style={{
                    position: "absolute",
                    fontSize: "25px",
                    color: "black",
                    top: 100,
                    left: 0,
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#F9F5F6",
                  }}
                >
                  Media Outreach
                </h2>
              </div>
            </Link>
          </Col>
        </Row>
        {/* <Modal
          show={showModal4}
          onHide={handleModalClose4}
          dialogClassName="custom-modal w-1000"
        >
          <Modal.Header closeButton>
            <Modal.Title>What We Offer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Media Outreach:</h4>
            <h9>
              Media outreach refers to the strategic efforts and activities
              undertaken by individuals, organizations, or businesses to connect
              and engage with various forms of media, such as newspapers,
              magazines, television, radio, online publications, and social
              media platforms. The goal of media outreach is to gain coverage
              and exposure for a specific message, story, product, event, or
              initiative. This can involve pitching press releases, arranging
              interviews, sending media kits, and building relationships with
              journalists and influencers to amplify the reach and impact of the
              content. It is the strategic management of communication between
              an organization and its target audience, with the aim of building
              and maintaining a positive reputation. We help in activities such
              as media relations, crisis management, event planning, and social
              media engagement to influence public perception and foster
              goodwill.
            </h9>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose4}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
      </Container>
    </>
  );
}

export default PublicRelations;

// <div class="portfolio">
//   <h1 style={{ textAlign: "center", color: "white", margin: "50px" }}>
//     Our Portfolio
//   </h1>
//   <div class="row mb-3">{/* Portfolio Images */}</div>
// </div>
