import PageBuilder from "../../utility/PageBuilder";

const MediaOutreachPage = () => {
  const content = [
    "At Atraski, we understand that effective public relations and media outreach are essential for building and maintaining a strong brand reputation. Our comprehensive PR strategies are designed to connect your brand with the right media outlets, influencers, and audiences, ensuring your message is heard loud and clear. Through targeted media outreach, we help you gain valuable exposure in print, online, and broadcast media, amplifying your brand’s presence and credibility.",
    "Our media outreach approach begins with identifying and understanding your key messages and goals. We then craft compelling press releases, pitches, and story angles that resonate with journalists and editors in your industry. By leveraging our extensive network of media contacts, we ensure your news and stories are placed in reputable publications that matter to your audience. This proactive strategy not only enhances your visibility but also builds trust and authority in your field.",
    "Atraski’s media outreach efforts are tailored to meet the unique needs of each client. We continuously monitor media trends and analyze the performance of our campaigns, providing you with detailed reports and insights. This allows us to refine our strategies and maximize the impact of your media coverage. Whether it's launching a new product, announcing a milestone, or managing a crisis, our team is dedicated to delivering timely and effective communication that supports your overall business objectives.",
    "In today's fast-paced digital world, a robust media outreach strategy is more important than ever. At Atraski, we combine traditional PR techniques with innovative digital tactics to ensure your brand stays relevant and top-of-mind. By securing positive media coverage and fostering strong relationships with key media players, we help you shape public perception and drive long-term success.",
  ];

  return <PageBuilder title="Media Outreach" content={content} />;
};

export default MediaOutreachPage;
