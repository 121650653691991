import PageBuilder from "../../utility/PageBuilder";

const AtlBtlBranding = () => {
  const content = [
    "Maximize your brand's reach and impact with Atraski's comprehensive Above The Line (ATL) and Below The Line (BTL) marketing strategies. We blend traditional and innovative approaches to deliver powerful campaigns that engage your target audience across multiple channels. Our ATL activities focus on mass media advertising, including television, radio, and print, to create broad brand awareness and reach a large audience. On the other hand, our BTL activities are more targeted and personalized, including direct mail, email marketing, and experiential marketing, to create a deeper connection with consumers and drive measurable results.",
    "By combining ATL and BTL activities, we create a holistic marketing approach that maximizes your brand's exposure and impact. Our team of experts works closely with you to understand your brand's unique identity and objectives, tailoring our strategies to meet your specific needs.",
    "Whether you're looking to launch a new product, promote an event, or simply enhance brand visibility, Atraski's ATL and BTL activities are designed to help you achieve your marketing goals efficiently and effectively",
  ];

  return (
    <PageBuilder
      title="Branding through ATL and BTL activities"
      content={content}
    />
  );
};

export default AtlBtlBranding;
