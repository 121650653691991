import PageBuilder from "../../utility/PageBuilder";

const CollegeAmbassador = () => {
  const content = [
    "The College Ambassador Program at Atraski is a vibrant community initiative designed to bridge the gap between brands and college students. This program empowers students to promote various product and service brands within their college campuses, leveraging their unique position to drive brand awareness and engagement. As college ambassadors, these students participate in a range of activities, from sharing company motives and participating in events to providing personal testimonials and answering questions from prospective customers.",
    "At Atraski, we understand the influential role that college students play in shaping brand perceptions among their peers. Our College Ambassador Program harnesses this influence by equipping students with the tools and knowledge they need to effectively represent and promote brands. Through hands-on training and support, we ensure that our ambassadors are well prepared to engage their college communities, providing authentic insights and fostering genuine connections.",
    "College ambassadors serve as a crucial link between the company and its potential customers. By actively participating in campus events, sharing their personal experiences with the brand, and disseminating information about products and services, they help to create a buzz and build trust within their networks. This grassroots approach not only enhances brand visibility but also provides valuable feedback and insights that can inform future marketing strategies.",
    "Joining the Atraski College Ambassador Program offers students a unique opportunity to gain real-world marketing experience, develop their communication and leadership skills, and build their professional network. For brands, this program is a powerful way to tap into the dynamic and influential college market, driving growth and establishing a strong presence among the youth. Partner with Atraski to launch a successful College Ambassador Program and watch your brand thrive on campuses nationwide.",
  ];

  return <PageBuilder title="College Ambassador" content={content} />;
};

export default CollegeAmbassador;
