import PageBuilder from "../../utility/PageBuilder";

const InfluencersCampaign = () => {
  const content = [
    "An influencer campaign is a powerful marketing strategy where brands collaborate with individuals who have a significant and engaged online following. At Atraski, we specialize in connecting your brand with the right influencers to promote your products, services, or messages across various online platforms, such as social media, blogs, and YouTube channels. By leveraging the influencer's credibility and reach, we aim to enhance your brand's visibility,   generate buzz, and drive meaningful engagement.",
    "Our influencer campaigns are tailored to align with your brand's objectives and target audience. We begin by identifying influencers whose followers match your ideal customer profile, ensuring a natural and authentic fit. Whether it's through sponsored content, product reviews, giveaways, or endorsements, we work closely with influencers to craft compelling narratives that resonate with their audience and highlight the unique value of your brand.",
    "At Atraski, we believe in the power of authentic storytelling. Influencers have the ability to create genuine connections with their followers, and we harness this power to amplify your brand’s message. By integrating your products or services into the influencer's everyday content, we ensure that the promotion feels organic and trustworthy. This approach not only boosts brand awareness but also fosters trust and loyalty among potential customers.",
    "Our team continuously monitors and analyzes the performance of influencer campaigns, providing you with detailed insights and reports. This allows us to optimize strategies in real-time and maximize the return on your investment. With Atraski's influencer campaigns, you can reach new audiences, enhance your brand's reputation, and achieve your marketing goals.",
  ];

  return <PageBuilder title="Influencers Campaign" content={content} />;
};

export default InfluencersCampaign;
