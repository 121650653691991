import React from "react";
import { Link } from "react-router-dom";
import Abouts from "./EAbout";
import Hero from "./Hero";
// import Perks from './Eshower/perks';
const Eshower = () => {
  return (
    <>
      <div className="line">
        <Hero />
        <Abouts />
      </div>
    </>
  );
};

export default Eshower;
