import PageBuilder from "../../utility/PageBuilder";

const DealersMeet = () => {
  const content = [
    "Enhance your dealer relationships with our meticulously planned dealer meets at Atraski. We focus on creating engaging, productive, and memorable events that foster strong connections and drive business growth. Our dealer meets are designed to provide a platform where you can connect with your dealers on a deeper level, share insights, and discuss strategies for mutual success. With a blend of professionalism and creativity, we ensure that every aspect of the event is tailored to meet your specific needs and objectives.",
    "At Atraski, we understand the importance of a well-organized event in strengthening dealer networks. From venue selection and logistics to interactive sessions and entertainment, our team handles every detail with precision and care. We curate experiences that are not only informative but also enjoyable, ensuring that your dealers leave with a sense of satisfaction and motivation. Our events are structured to encourage open communication, idea exchange, and collaborative problem - solving, all of which are crucial for building strong and lasting relationships.",
    "Interactive sessions form the cornerstone of our dealer meets. We design these sessions to be highly engaging, providing valuable insights into market trends, product updates, and sales strategies. By fostering an environment of learning and collaboration, we help your dealers feel more connected to your brand and more confident in their role within your business ecosystem. These sessions are complemented by entertainment and networking opportunities, creating a balanced and enriching experience for all attendees.",
    "Choosing Atraski for your dealer meet means partnering with a team dedicated to excellence and innovation. We are committed to delivering seamless and impactful events that not only meet but exceed your expectations. Let us help you create an unforgettable dealer meet that strengthens your partnerships, boosts morale, and drives business growth. With Atraski, you can be assured of an event that is as productive as it is memorable, leaving a lasting impression on your dealers and setting the stage for continued success.",
  ];

  return <PageBuilder title="Dealers meet" content={content} />;
};

export default DealersMeet;
