import PageBuilder from "../../utility/PageBuilder";

const RunningAdsPage = () => {
  const content = [
    "Social media advertising is a powerful tool for businesses to reach their target audience with precision and impact. At Atraski, we pecialize in crafting compelling ad campaigns on platforms like Facebook, Instagram, and LinkedIn, delivering tailored messages and ngaging content to with your audience, driving meaningful interactions and conversions.",
    "One of the standout features of social media advertising is the unparalleled targeting options available. By leveraging data on demographics, interests, and behavior, we can ensure that your ads reach the most relevant audience, maximizing relevance and return on investment (ROI). This level of precision targeting allows us to create highly personalized ad experiences that speak directly to the needs and desires of your target market.",
    "At Atraski, we believe in the power of data to drive results. Our social media ad campaigns come with real-time performance tracking and analytics, providing you with insights into how your ads are performing. This transparency allows us to continuously optimize your campaigns for better results, adjusting strategies based on what works best. From boosting brand visibility to driving website traffic and generating leads, our data-driven approach ensures that your marketing dollars are spent efficiently.",
    "Social media advertising is a game-changer in the world of digital marketing, offering businesses endless opportunities for growth and success. With Atraski as your partner, you can unlock the full potential of social media ads, leveraging our expertise to achieve your business goals. Let us help you navigate this dynamic landscape with strategies that resonate and results that matter, ensuring your brand stands out in the crowded digital space.",
  ];

  return <PageBuilder title="Running Ads" content={content} />;
};

export default RunningAdsPage;
