import PageBuilder from "../../utility/PageBuilder";

const SocialMediaManagerPage = () => {
  const content = [
    "At Atraski, we are more than a digital marketing agency; we are your partners in building a powerful online presence. Our social media marketing services are designed to elevate your brand with strategies that capture its unique essence. Leveraging the power of platforms like Facebook, Instagram, Twitter, and LinkedIn, we combine creative content with data-driven insights to deliver results that truly matter.",
    "What sets Atraski apart is our dedication to excellence and innovation. We believe every brand has a unique story, and our mission is to tell yours in the most compelling way. Our team of seasoned experts stays ahead of the curve, exploring new trends and techniques to keep your brand at the forefront of social media. From engaging visuals to strategic ad placements, we ensure your brand's voice resonates with your target audience.",
    "Our approach is collaborative and transparent, ensuring you are involved and informed at every step. We take the time to understand your brand’s identity and objectives, crafting customized strategies that align with your vision. Using advanced analytics, we track and optimize the performance of your campaigns, providing actionable insights and tangible results. Our goal is not just to drive awareness and engagement but also to foster growth and achieve your business objectives.",
    "Experience the Atraski difference, where every post, tweet, and story is crafted with precision and passion. Let us help you navigate the dynamic world of social media marketing with strategies that resonate and results that matter. With Atraski, your brand is not just seen – it's remembered.",
  ];

  return <PageBuilder title="Social Media Manager" content={content} />;
};

export default SocialMediaManagerPage;
