import { Fragment } from "react";
import { Link } from "react-router-dom";

import SideNav from "../components/SideNav";
import pic1 from "../images/InfluencersCampaign.PNG";
import pic2 from "../images/College Ambassador.png";
import pic3 from "../images/Campaign Youth Marketing.PNG";
import "./ServicesPage.css";

const TestInfluentialMarketing = () => {
  const DMLink = "/services/influential-marketing";

  const listItem = [
    { name: "Influencers Campaign", link: `${DMLink}/influencers-campaign` },
    { name: "College Ambassador", link: `${DMLink}/college-ambassador` },
    { name: "Campaign / Youth Marketing", link: `${DMLink}/youth-marketing` },
  ];

  return (
    <Fragment>
      <div className="service-container">
        <h1>Influential Marketing</h1>
        <div className="service-cards-container">
          <Link to={`${DMLink}/influencers-campaign`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic1} alt="Image 1" />
              <h2>Influencers Campaign</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/college-ambassador`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic2} alt="Image 1" />
              <h2>College Ambassador</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/youth-marketing`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic3} alt="Image 1" />
              <h2>Campaign / Youth Marketing</h2>
            </div>
          </Link>
        </div>
      </div>

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default TestInfluentialMarketing;
