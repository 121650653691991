/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import offer1 from "../images/my voice goods and service.PNG";
import "../index.css";

function Prod() {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Container>
        <h1 className="mt-5 text-white text-center">My City My Voice</h1>
        <h3 className="mt-3 text-white text-center">What We Offer</h3>
        <Row
          className="my-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col md={4}>
            <div
              className="image-container"
              onClick={handleImageClick}
              style={{ cursor: "pointer" }}
            >
              <img src={offer1} alt="Image 1" className="img-fluid" />
              <h2
                className="image-heading"
                style={{
                  position: "absolute",
                  color: "black",
                  top: 100,
                  left: 0,
                  width: "100%",
                  textAlign: "center",
                  fontSize: "25px",
                  backgroundColor: "#F9F5F6",
                }}
              >
                Goods and Services
              </h2>
            </div>
          </Col>
        </Row>

        <Modal
          show={showModal}
          onHide={handleModalClose}
          dialogClassName="custom-modal w-1000"
        >
          <Modal.Header closeButton>
            <Modal.Title>What We Offer:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Goods and Services:</h4>
            <h6 style={{ color: "grey", fontSize: "15px" }}>
              My City My Voice is a platform committed to blowing the wind
              beneath the wings of aspiring writers and2 budding stand-up
              speakers to project their art across the nation. We have
              successfully executed our events in 12+ cities building a
              community of more than 1000 people. Our county has a huge number
              of talented artist. We are not only encouraging their talents but
              giving them opportunities to brighten their skills. Laying out the
              best of the maestros from the core of the country. We provide the
              opportunity for you to portray your competence and abide by the
              famous saying “ability is nothing without opportunity”, so resolve
              to seize it!
            </h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default Prod;
