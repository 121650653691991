import { Card, Row, Col } from "react-bootstrap";

import video1 from "../components/videos/jewel.mp4";
import video2 from "../components/videos/haldiram.mp4";
import video3 from "../components/videos/eazydinner.mp4";
import video4 from "../components/videos/mogasun.mp4";
import video5 from "../components/videos/dilemughli.mp4";
import video6 from "../components/videos/DY Patil.mp4";

const WorkVideos = () => {
  return (
    <div className="container-fluid">
      {/* <h2 className="text-center mb-0" style={{ marginBottom: '10px', color:'#fc0c8c' , fontWeight:'900'}}>Successful Projects</h2> */}
      <Row>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  JewelBox
                </p>
              </div>
              <video
                src={video1}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  Haldiram
                </p>
              </div>
              <video
                src={video2}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  Eazy Dinner
                </p>
              </div>
              <video
                src={video3}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "0px",
              marginTop: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  Mog Asun
                </p>
              </div>
              <video
                src={video4}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  Dil-E-Mughlai
                </p>
              </div>
              <video
                src={video5}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="my-5">
          <Card
            style={{
              marginBottom: "30px",
              marginTop: "30px",
              margin: "auto",
              background: "#fc0c8c",
              maxWidth: "366px",
            }}
          >
            <Card.Body
              style={{ backgroundColor: "white", position: "relative" }}
            >
              <div className="video-overlay">
                <p
                  className="description text-center text-bold"
                  style={{ marginBottom: "-40px" }}
                >
                  DY Patil
                </p>
              </div>
              <video
                src={video6}
                autoPlay
                muted
                loop
                className="video-element"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WorkVideos;
