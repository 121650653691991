import { Link } from "react-router-dom";
import "./SideNav.css";

const SideNav = ({ listItems }) => {
  const list = listItems.map((list, index) => {
    return (
      <Link to={list.link} key={index}>
        <li>{list.name}</li>
      </Link>
    );
  });

  return (
    <div className="sidenav">
      <ul className="sidenav-list">{list}</ul>
    </div>
  );
};

export default SideNav;
