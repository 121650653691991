import { Fragment } from "react";
import { Link } from "react-router-dom";

import SideNav from "../components/SideNav";
import pic1 from "../components/imagegallery/dealer.png";
import pic2 from "../components/imagegallery/stall.png";
import pic3 from "../components/imagegallery/launch.png";
import pic4 from "../components/imagegallery/atlbtl.png";
import "./ServicesPage.css";

const TestEventsExhibition = () => {
  const DMLink = "/services/event&exhibition";

  const listItem = [
    { name: "Dealers Meet", link: `${DMLink}/dealers-meet` },
    { name: "Stall Fabrication", link: `${DMLink}/stall-fabrication` },
    { name: "Launch Event", link: `${DMLink}/launch-event` },
    {
      name: "Branding through ATL and BTL activities",
      link: `${DMLink}/atl-btl-branding`,
    },
  ];

  return (
    <Fragment>
      <div className="service-container">
        <h1>Events & Exhibition</h1>
        <div className="service-cards-container">
          <Link to={`${DMLink}/dealers-meet`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic1} alt="Image 1" />
              {/* <h2>Dealers Meet</h2> */}
            </div>
          </Link>
          <Link to={`${DMLink}/stall-fabrication`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic2} alt="Image 1" />
              {/* <h2>Stall Fabrication</h2> */}
            </div>
          </Link>
          <Link to={`${DMLink}/launch-event`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic3} alt="Image 1" />
              {/* <h2>Launch Event</h2> */}
            </div>
          </Link>
          <Link to={`${DMLink}/atl-btl-branding`}>
            <div className="service-card" style={{ cursor: "pointer" }}>
              <img src={pic4} alt="Image 1" />
              {/* <h2 style={{ fontSize: "1rem" }}>
                Branding through ATL and BTL activities
              </h2> */}
            </div>
          </Link>
        </div>
      </div>

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default TestEventsExhibition;
