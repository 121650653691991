// for local developement
// const API_1 = "http://localhost:5000/Order";
// const API_2 = "http://localhost:5000/key";
// const API_3 = "http://localhost:5000/verification";
// const API_4 = "http://localhost:5000/Order6";
// const API_5 = "http://localhost:5000/saveDataToDatabase6";
// const API_6 = "http://localhost:5000/Order5";
// const API_7 = "http://localhost:5000/saveDataToDatabase5";
// const API_8 = "http://localhost:5000/Order1";
// const API_9 = "http://localhost:5000/Order7";
// const API_10 = "http://localhost:5000/fashionDesignerNew";
// const API_11 = "http://localhost:5000/Order8";
// const API_12 = "http://localhost:5000/Order9";
// const API_13 = "http://localhost:5000/createExperiencedDesigner";
// const API_14 = "http://localhost:5000/createPlaceLogo";
// const API_15 = "http://localhost:5000/Order10";
// const API_16 = "http://localhost:5000/createFashionAttendee";
// const API_17 = "http://localhost:5000/Order3";
// const API_18 = "http://localhost:5000/createModelForm";
// const API_19 = "http://localhost:5000/createBloggerForm";
// const API_20 = "http://localhost:5000/Order12";
// const API_21 = "http://localhost:5000/createMumbaiBloggerForm";

// for production
const API_1 = "https://www.api.atraski.com/Order";
const API_2 = "https://www.api.atraski.com/key";
const API_3 = "https://www.api.atraski.com/verification";
const API_4 = "https://www.api.atraski.com/Order6";
const API_5 = "https://www.api.atraski.com/saveDataToDatabase6";
const API_6 = "https://www.api.atraski.com/Order5";
const API_7 = "https://www.api.atraski.com/saveDataToDatabase5";
const API_8 = "https://www.api.atraski.com/Order1";
const API_9 = "https://www.api.atraski.com/Order7";
const API_10 = "https://www.api.atraski.com/fashionDesignerNew";
const API_11 = "https://www.api.atraski.com/Order8";
const API_12 = "https://www.api.atraski.com/Order9";
const API_13 = "https://www.api.atraski.com/createExperiencedDesigner";
const API_14 = "https://www.api.atraski.com/createPlaceLogo";
const API_15 = "https://www.api.atraski.com/Order10";
const API_16 = "https://www.api.atraski.com/createFashionAttendee";
const API_17 = "https://www.api.atraski.com/Order3";
const API_18 = "https://www.api.atraski.com/createModelForm";
const API_19 = "https://www.api.atraski.com/createBloggerForm";
const API_20 = "https://www.api.atraski.com/Order12";
const API_21 = "https://www.api.atraski.com/createMumbaiBloggerForm";

export {
  API_1,
  API_2,
  API_3,
  API_4,
  API_5,
  API_6,
  API_7,
  API_8,
  API_9,
  API_10,
  API_11,
  API_12,
  API_13,
  API_14,
  API_15,
  API_16,
  API_17,
  API_18,
  API_19,
  API_20,
  API_21,
};
