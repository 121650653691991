/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import offer1 from "../images/Incentives, Conferences.PNG";
import offer2 from "../images/Business events..jpg";

const Mice = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  return (
    <div>
      <Container>
        <h1 className="mt-5 text-white text-center">Book Our Stays</h1>
        <h3 className="mt-3 text-white text-center">What We Offer</h3>
        <Row
          className="my-4"
          style={{
            pointerEvents: "cursor",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col md={4} style={{ cursor: "pointer" }}>
            <div className="image-container" onClick={handleImageClick}>
              <img src={offer1} alt="Image 1" className="img-fluid" />
              <h2
                className="image-heading"
                style={{
                  position: "absolute",
                  fontSize: "25px",
                  color: "black",
                  top: 100,
                  left: 0,
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "#F9F5F6",
                }}
              >
                Incentives, Conferences
              </h2>
            </div>
          </Col>
        </Row>

        <Modal
          show={showModal}
          onHide={handleModalClose}
          dialogClassName="custom-modal w-1000"
        >
          <Modal.Header closeButton>
            <Modal.Title>What We Offer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Incentives, Conferences:</h4>
            <h9>
              Our AtStay Travel Agency provides incentives which often provide
              tools and software to track and manage travel expenses, making it
              easier for businesses to monitor and analyze their travel
              spending. We can organize and manage group travel for conferences,
              meetings, or corporate events. Our agency may assist with visa
              applications, passport renewals, and other necessary documentation
              for international travel. We can offer advice and consultation on
              creating or updating corporate travel policies to improve
              efficiency and cost-effectiveness.
            </h9>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Mice;
