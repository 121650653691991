import { useEffect } from "react";

import CareerProfile from "../utility/CareerProfile";
import anish from "../images/Anish.jpg";
import abhy from "../images/Abhyuday.jpg";
import priti from "../images/Priti.jpg";
import priyanka from "../images/Priyanka.jpg";
import tridip from "../images/Tridip.jpg";

import anishka from "../images/Anishka.png";
import neha from "../images/Neha.png";
import snehal from "../images/Snehal.png";
import vineet from "../images/Vineet.png";
import akhilesh from "../images/Akhilesh.png";
import rachit from "../images/Rachit.png";
import saaim from "../images/Saaim.png";
import shweta from "../images/Shweta.png";
import gaunika from "../images/Gaunika.png";
import mehul from "../images/Mehul.png";
import shivangi from "../images/Shivangi.png";
import nandini from "../images/Nandini.png";
import amritam from "../images/Amritam.png";
import soumashish from "../images/Soumasish.png";
import piyush from "../images/Piyush.png";
import anand from "../images/Anand.png";
import priyansh from "../images/Priyansh.png";

const Careers = () => {
  useEffect(() => {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
      link.setAttribute("rel", "noopener noreferrer");
    });
  }, []);

  const careerData = [
    // Founder
    {
      name: "Anish Dhar",
      img: anish,
      designation: "CEO - Founder",
      linkedIn:
        "https://www.linkedin.com/in/anish-dhar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bx8gYUaYhTcyMIoijF%2FI1DA%3D%3D",
    },

    // HR
    {
      name: "Anishka Pal",
      img: anishka,
      designation: "Sr. HR Executive",
      linkedIn: "https://www.linkedin.com/in/anishka-pal-4891b5233/",
    },

    // Operations
    {
      name: "Akhilesh Gupta",
      img: akhilesh,
      designation: "Sr. Operations Executive",
      linkedIn: "https://www.linkedin.com/in/akhilesh-kumar-gupta-42579916b/",
    },

    // Business Development
    {
      name: "Priti Jhawar",
      img: priti,
      designation: "Sr. BD Executive",
      linkedIn:
        "https://www.linkedin.com/in/priti-jhawar-91005a204?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BpiticpQmRGyXMO7%2ByBzF2w%3D%3D",
    },
    {
      name: "Rachit Garg",
      img: rachit,
      designation: "BD Executive",
      linkedIn: "https://www.linkedin.com/in/rachit-garg-935185275/",
    },
    {
      name: "Vineet Kumar",
      img: vineet,
      designation: "BD Executive",
      linkedIn: "https://www.linkedin.com/in/vineetkumar2002/",
    },
    {
      name: "Neha Pal",
      img: neha,
      designation: "BD Executive",
      linkedIn: "https://www.linkedin.com/in/neha-pal-701a72272/",
    },

    // Web
    {
      name: "Snehal Gadge",
      img: snehal,
      designation: "Web Developer",
      linkedIn: "https://www.linkedin.com/in/snehal-gadge-28a966201/",
    },

    // Marketing
    {
      name: "Abhyuday Majhi",
      img: abhy,
      designation: "Sr. Marketing Executive",
      linkedIn: "https://www.linkedin.com/in/abhyuday-majhi-52737b193/",
    },

    // Marcom
    {
      name: "Shweta Saini",
      img: shweta,
      designation: "Marcom Manager",
      linkedIn: "https://www.linkedin.com/in/heyshweta/",
    },
    {
      name: "Gaunika Saberwal",
      img: gaunika,
      designation: "Marcom Executive",
      linkedIn: "https://www.linkedin.com/in/gaunika-saberwal-71270b227/",
    },
    {
      name: "Mehul Malik",
      img: mehul,
      designation: "Marcom Executive",
      linkedIn: "https://www.linkedin.com/in/mehul-malik1/",
    },
    {
      name: "Shivangi Jhamb",
      img: shivangi,
      designation: "Marcom Executive",
      linkedIn: "https://www.linkedin.com/in/shivangi-jhamb-083799213/",
    },
    {
      name: "Nandini Aggarwal",
      img: nandini,
      designation: "Marcom Executive",
      linkedIn: "https://www.linkedin.com/in/nandini-aggarwal-447173223/",
    },

    // Production
    {
      name: "Tridip Chowdhury",
      img: tridip,
      designation: "Sr. Production Executive",
      linkedIn:
        "https://www.linkedin.com/in/tridip-chowdhury-5710b51a6?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B1MIrT5%2BGSSK9zJpTRKkC8Q%3D%3D",
    },

    // Designer
    {
      name: "Amritam Sarkar",
      img: amritam,
      designation: "Motion Graphic Designer",
      linkedIn: "https://www.linkedin.com/in/amritam-sarkar/",
    },
    {
      name: "Soumasish Datta",
      img: soumashish,
      designation: "Graphic Designer",
      linkedIn: "https://www.linkedin.com/in/soumasish-datta-391a70189/",
    },
    {
      name: "Piyush Kumar",
      img: piyush,
      designation: "Motion Graphic Designer",
      linkedIn: "https://www.linkedin.com/in/piyush-kumar-701215247/",
    },

    // PR
    {
      name: "Priyanka Basu",
      img: priyanka,
      designation: "Sr. PR Executive",
      linkedIn:
        "https://www.linkedin.com/in/priyanka-basu-2063ab192?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B9qhO%2FZ0lT1%2BQLAIOKTIY6A%3D%3D",
    },
  ];

  const internData = [
    {
      name: "Saaim Iqbal",
      img: saaim,
      designation: "Marcom Intern",
      linkedIn: "https://www.linkedin.com/in/saaimiqbal1112/",
    },
    {
      name: "Priyansh Singh",
      img: priyansh,
      designation: "Web Development Intern",
      linkedIn: "https://www.linkedin.com/in/priyansh-singh-867414213/",
    },
  ];

  const careerProfile = careerData.map((empInfo, index) => {
    return (
      <CareerProfile
        key={index}
        name={empInfo.name}
        image={empInfo.img}
        designation={empInfo.designation}
        linkedin={empInfo.linkedIn}
      />
    );
  });

  const internProfile = internData.map((internInfo, index) => {
    return (
      <CareerProfile
        key={index}
        name={internInfo.name}
        image={internInfo.img}
        designation={internInfo.designation}
        linkedin={internInfo.linkedIn}
      />
    );
  });

  return (
    <>
      <div class="container py-5">
        <div class="row text-center text-white">
          <div class="col-lg-8 mx-auto">
            <h1 class="display-4">Our Team</h1>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row text-center">{careerProfile}</div>
      </div>
      <div class="container">
        <h2 className="text-center mb-4 text-white">Interns</h2>
        <div class="row text-center">{internProfile}</div>
      </div>

      {/* <div className="container">
        <h2 className="text-center mb-4 text-white">Job Openings</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ color: "black", fontSize: "20px" }}
                >
                  Business Development Executive
                </h5>
                <span class="bi bi-briefcase-fill">Full Time</span>
                <br></br>
                <span class="bi bi-plus">Actively Hiring</span>
                <br></br>
                <span class="bi bi-geo-alt-fill">Delhi,India</span>
                <br></br>

                <a
                  href="https://www.linkedin.com/jobs/view/3616374996"
                  className="btn btn-primary"
                  style={{ margin: "10px" }}
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ color: "black", fontSize: "20px", margin: "18px" }}
                >
                  Digital Marketing Intern
                </h5>
                <span class="bi bi-briefcase-fill">Full Time</span>
                <br></br>
                <span class="bi bi-plus">Actively Hiring</span>
                <br></br>
                <span class="bi bi-geo-alt-fill">Delhi,India</span>
                <br></br>

                <a
                  href="https://www.linkedin.com/jobs/view/3616374996"
                  className="btn btn-primary"
                  style={{ margin: "10px" }}
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{
                    color: "black",
                    fontSize: "20px",
                    marginBottom: "16px",
                  }}
                >
                  Digital Marketing Intern
                </h5>
                <span class="bi bi-briefcase-fill">Full Time</span>
                <br></br>
                <span class="bi bi-plus">Actively Hiring</span>
                <br></br>
                <span class="bi bi-geo-alt-fill">Delhi,India</span>
                <br></br>
                <a
                  href="https://www.linkedin.com/jobs/view/3616374996"
                  className="btn btn-primary"
                  style={{ margin: "19px" }}
                  target="_blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Careers;
