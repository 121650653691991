import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../index.css";
import subimg1 from "./imagegallery/dealer.png";
import subimg2 from "./imagegallery/stall.png";
import subimg3 from "./imagegallery/launch.png";
import subimg4 from "./imagegallery/atlbtl.png";
import e1 from "../images/e15.jpg";
import e2 from "../images/e16.png";
import e3 from "../images/e17.png";
import e4 from "../images/e18.png";
import portfolio1 from "../images/e1.jpg";
import portfolio2 from "../images/e2.jpg";
import portfolio3 from "../images/e3.jpg";
import portfolio4 from "../images/e4.jpg";
import portfolio5 from "../images/e5.jpg";
import portfolio6 from "../images/e6.jpg";
import portfolio7 from "../images/e7.jpg";

function EventExhibition() {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalClose2 = () => {
    setShowModal2(false);
  };

  const handleModalClose3 = () => {
    setShowModal3(false);
  };

  const handleModalClose4 = () => {
    setShowModal4(false);
  };

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleImageClick2 = () => {
    setShowModal2(true);
  };

  const handleImageClick3 = () => {
    setShowModal3(true);
  };

  const handleImageClick4 = () => {
    setShowModal4(true);
  };

  return (
    <>
      <div className="container">
        <h1 className="text-center mb-3 font-weight-bold">
          Events & Exhibition
        </h1>
      </div>
      <Container style={{ marginBottom: "5rem" }}>
        <h3 className="mt-3  text-white text-center">What We Offer</h3>
        <Row className="my-3">
          <Col md={3}>
            <Link to="dealers-meet">
              <div
                className="image-container mt-5"
                style={{ cursor: "pointer" }}
              >
                <img src={subimg1} alt="Image 1" className="img-fluid" />
              </div>
            </Link>
          </Col>
          <Col md={3}>
            <Link to="stall-fabrication">
              <div
                className="image-container mt-5"
                style={{ cursor: "pointer" }}
              >
                <img src={subimg2} alt="Image 2" className="img-fluid" />
              </div>
            </Link>
          </Col>
          <Col md={3}>
            <Link to="launch-event">
              <div
                className="image-container mt-5"
                style={{ cursor: "pointer" }}
              >
                <img src={subimg3} alt="Image 3" className="img-fluid" />
              </div>
            </Link>
          </Col>
          <Col md={3}>
            <Link to="atl-btl-branding">
              <div
                className="image-container mt-5"
                onClick={handleImageClick4}
                style={{ cursor: "pointer" }}
              >
                <img src={subimg4} alt="Image 4" className="img-fluid" />
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      {/* Add portfolio section and footer */}
    </>
  );
}

export default EventExhibition;
