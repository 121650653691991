import PageBuilder from "../../utility/PageBuilder";

const StallFabrication = () => {
  const content = [
    "Stand out at any exhibition with Atraski's custom stall fabrication services. We specialize in designing and building eye-catching, functional stalls that reflect your brand's identity and attract maximum footfall. Our expert team handles everything from conceptualization to installation, ensuring that your exhibition space is not just a booth, but an immersive brand experience that captivates and converts visitors.With Atraski, you can be confident that your stall will stand out from the crowd. We combine innovative design concepts with high-quality materials and skilled craftsmanship to create stalls that are not only visually stunning but also highly functional. Whether you're looking for a sleek and modern design or a more traditional and elegant approach, we have the expertise and creativity to bring your vision to life. Trust Atraski to make your next exhibition a memorable success with our top-notch stall fabrication services.",
  ];

  return <PageBuilder title="Stall Fabrication" content={content} />;
};

export default StallFabrication;
