import React, { useEffect, useState } from "react";
import "./fashionRegistration.css";
import fashion from "../images/Copy of DELHI FASHION CONFLUENCE.jpg";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_2, API_3, API_4, API_5 } from "../api/api";

export default function SponsorsRegistration() {
  const [name, setName] = useState(" ");
  const [labelName, setLabelName] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [city, setCity] = useState(" ");
  const [years, setYears] = useState(" ");
  const [insta, setInsta] = useState(" ");
  const [fb, setFb] = useState(" ");
  const [twitter, setTwitter] = useState(" ");
  const [dresses, setDresses] = useState(" ");

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const checkout = async (amount) => {
    localStorage.setItem("amount", amount); // Set the 'amount' in localStorage\
    try {
      if (1) {
        var data1 = await fetch(API_4, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount, name, email, Number }),
        });

        var keys = await fetch(API_2, {
          method: "GET",
        });
        keys = await keys.json();
        console.log(keys, "yes");
        data1 = await data1.json();

        // const keys='rzp_test_OmCfFJhnp3Fztn'
        console.log(keys);
        console.log(data1.amount);
        console.log(data1.id);
        console.log(data1);
      } else {
        alert("Please Enter Valid Number");
      }
      if (data1.success) {
        const options = {
          key: keys.key, // Enter the Key ID generated from the Dashboard
          amount: data1.order.tot, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Atraski Fashion Designer Registration", //your business name
          description: "",
          // image: "https://example.com/your_logo",
          order_id: data1.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: API_3,
          handler: function(response) {
            // Handle the payment success callback here
            console.log("Payment successful: ", response);
            try {
              navigate("/");
            } catch (error) {
              console.error("Navigation error:", error);
            }
            // You can navigate to a success page or perform further actions here

            // Save data to the database (you need to implement this on your backend)
            saveDataToDatabase();
          },
          // prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
          //     name: "Gaurav Kumar", //your customer's name
          //     email: "gaurav.kumar@example.com",
          //     contact: "9000090000" //Provide the customer's phone number for better conversion rates
          // },

          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#ffc0cb",
          },
        };
        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.success", function(response) {
          // Payment was successful, now save data to the database
          saveDataToDatabase();
          console.log("Payment successful:", response);
          // You can navigate to a success page or perform further actions here
        });

        rzp1.open();
      } else {
        // console.error("Error creating Razorpay order:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  const saveDataToDatabase = async () => {
    try {
      const paisa = localStorage.getItem("amount");

      // Send a request to your server to save data to the database
      const response = await fetch(API_5, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,

          Number,
          amount: paisa,
          insta,
        }),
      });

      const data = await response.json();

      if (data.success) {
        console.log("Data saved successfully:", data);
        // You can show a success message to the user
      } else {
        console.error("Error saving data:", data.error);
        // Handle the error, e.g., show an error message to the user
      }
    } catch (error) {
      console.error("Error saving data:", error);
      // Handle the error, e.g., show an error message to the user
    }
  };

  return (
    <div>
      <div
        className="container-fluid pb-5 pms"
        style={{
          width: "100vw",
          height: "100%",
          background: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="d-flex w-100 formflex"
          style={{ justifyContent: "space-between", gap: "1.5rem" }}
        >
          <div className="Details" style={{ width: "700px" }}>
            <div className="location mt-5 ">
              <h4
                style={{
                  color: "black",
                  fontSize: "16px",
                  textAlign: "justify",
                }}
              >
                <span style={{ fontWeight: "700", color: "#fc0c8c" }}>
                  Sponsorship
                </span>

                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  TITLE Sponsor (1 Lakh)
                  <br />
                </span>
                <ul>
                  <li>
                    We will have your logo along with the Delhi Fashion
                    Confluence name as “Title Sponsors” and the brand will enjoy
                    all the marketing the fashion week will undertake.
                  </li>
                  <li>
                    The title sponsor's logo will be on the Main event stage,
                    banners, standees, posters, photo ops, brochures, identity
                    cards, and hampers.
                  </li>
                  <li>
                    The brand would be provided with a stall in the event at a
                    prime location (if needed).
                  </li>
                  <li>
                    Promotion on all the Social Media platforms of Delhi Fashion
                    Confluence.
                  </li>
                  <li>
                    After-film brand coverage, which will be broadcast on
                    YouTube after the festival.
                  </li>
                  <li>
                    Acknowledgement of the brand by the radio partner and during
                    the event itself.
                  </li>
                </ul>
                <span style={{ fontWeight: "700" }}>
                  POWER Sponsor (50k-30k)
                  <br />
                </span>
                <ul>
                  <li>
                    Delhi Fashion Confluence will have the brand’s logo
                    alongside its name as “powered by”.
                  </li>
                  <li>
                    The Power Sponsor’s logo will be on the Banners, Standees,
                    Posters, Brochures, Photo ops, and identity cards of the
                    volunteers for the event as “powered by”.
                  </li>
                  <li>
                    The brand would be provided with a stall at the event.
                    Promotion on Social media platforms of the Delhi Fashion
                    Confluence. Acknowledgement during the Main Event.
                  </li>
                </ul>
                <span style={{ fontWeight: "700" }}>
                  CO- Sponsor (20k)
                  <br />
                </span>
                <ul>
                  <li>
                    We will have your logo along with the Delhi Fashion
                    Confluence name as “Co-Sponsored by”.
                  </li>
                  <li>
                    The Co sponsor's logo will be on the Banners, Standees,
                    Posters, and Photo ops for the event.
                  </li>
                  <li>
                    The brand would be provided with a stall at the event.
                  </li>
                  <li>
                    Promotion on social media platforms of the Delhi Fashion
                    Confluence.
                  </li>
                  <li>Rise during the Main Event.</li>
                </ul>
              </h4>
            </div>
          </div>

          <div className="registars mt-5" style={{ width: "700px" }}>
            <h4
              style={{ color: "black", fontSize: "16px", textAlign: "justify" }}
            >
              <span>
                <span style={{ fontWeight: "700", color: "#fc0c8c" }}>
                  {" "}
                  Benefits for All Registering Individuals:
                </span>
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Event Attendance:
                  <br />
                </span>
                Description: All applicants, regardless of selection, have the
                opportunity to attend the event.
                <br /> <br />
                <span style={{ fontWeight: "700" }}>
                  Access to After Party:
                  <br />
                </span>
                Description: Selected models gain exclusive access to the
                after-party, enhancing their overall experience and providing
                networking opportunities.
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Model Walk Practice:
                  <br />
                </span>
                Description: Participating models receive dedicated practice
                sessions to refine their runway skills, ensuring preparedness
                for the event.
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Model After Party:
                  <br />
                </span>
                Description: Invitation to a special after-party provides models
                with networking opportunities, a chance to celebrate, and
                potential connections within the fashion industry.
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Goodies from Different Brands:
                  <br />
                </span>
                Description: Models receive goodies from various brands,
                providing a positive incentive for participation and the
                opportunity to experience and promote new products.
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Media Coverage:
                  <br />
                </span>
                Description: The event ensures media coverage, offering models
                exposure and potential recognition within the industry.
                <br />
                <br />
                <span style={{ fontWeight: "700" }}>
                  Portfolio Shoot:
                  <br />
                </span>
                Description: A portfolio shoot is provided as a valuable
                opportunity for models to enhance their professional portfolio,
                showcasing versatility and style.
              </span>
            </h4>
          </div>
        </div>
        {/*<div className="mainbox d-flex">
          <div className="leftbox w-45 mt-5 mr-3">
            <img src={fashion} width="100%" height="55%" className="imagform" />

          </div>

          <div className="rightbox w-50 mt-5">
            <h2
              className=" headingss"
              style={{
                color: "#fc0c8c",
                fontWeight: "700",
                fontSize: "40px",
                borderBottom: "5px solid #fc0c8c",
                width: "80%",
              }}
            >
              DELHI FASHION2024 CONFLUENCE
            </h2>

            <div className="price mt-3">
              <span>
                <del>₹19,999</del>
              </span>{" "}
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: "900",
                  color: "#fc0c8c",
                }}
              >
                ₹17,700(15,000+18% GST Included)
              </span>
            </div>

            <div className="date mt-4">
              <h4
                className=""
                style={{ color: "#000", fontSize: "17px", fontWeight: "700" }}
              >
                Date: 29th December, Friday
              </h4>
            </div>

            <div className="time mt-4">
              <h4
                className=""
                style={{ color: "#000", fontSize: "17px", fontWeight: "700" }}
              >
                Time: 5pm onwards
              </h4>
            </div>

            <div className="location mt-4">
              <h4
                className=""
                style={{ color: "#000", fontSize: "17px", fontWeight: "700" }}
              >
                Location:{" "}
                <span style={{ color: "#fc0c8c" }}>La Vie En Rose Cafe</span>
              </h4>
            </div>

            <div className="map mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.7873525429954!2d77.1947250749524!3d28.516044889362007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1a14b923753%3A0x3f6fe0a8810d3bf5!2sLa%20Vie%20En%20Rose%2C%20Atraski-%20Co-working%20Space%20%26%20Virtual%20Office!5e0!3m2!1sen!2sin!4v1702276527541!5m2!1sen!2sin"
                className="mapss"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
              </div> */}

        <div className="w-100"></div>
        <div className="forms mt-5" style={{ width: "100%" }}>
          <div
            className="formbox"
            style={{
              width: "100%",
              height: "100%",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
              background: "#fc0c8c",
              textAlign: "center",
              padding: "1rem",
            }}
          >
            <span
              className=""
              style={{ color: "white", fontSize: "25px", fontWeight: "700" }}
            >
              Sponsors Registration
            </span>
          </div>
          <div
            className="formbox"
            style={{
              width: "100%",
              height: "100%",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                checkout(17700);
              }}
              style={{ margin: "0", maxWidth: "100%" }}
            >
              <Form.Group controlId="name" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Designer Name:
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  placeholder="Enter your name"
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="labelName" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Label Name:
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="labelName"
                  value={labelName}
                  onChange={(e) => {
                    setLabelName(e.target.value);
                  }}
                  placeholder="Enter your Label name"
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="email" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Email Id:
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  // placeholder="Enter your email"
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="city" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Town/City
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  className="mb-3 cls"
                  placeholder="Enter your city/town"
                  required
                />
              </Form.Group>

              <Form.Group controlId="years" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Number of years in the business:
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="number"
                  name="years"
                  min={0}
                  value={years}
                  onChange={(e) => {
                    setYears(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              {/*<Form.Group controlId="checkOut" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Mobile(whatsapp)
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="checkOut"
                  value={Number}
                  onChange={(e) => {
                    setno(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
                </Form.Group>*/}

              <Form.Group controlId="insta" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Brand Instagram Link
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="insta"
                  placeholder="Instagram URL"
                  value={insta}
                  onChange={(e) => {
                    setInsta(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="fb" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Brand Facebook Link
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="fb"
                  placeholder="Instagram URL"
                  value={fb}
                  onChange={(e) => {
                    setFb(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="twitter" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  Brand Twitter Link
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="text"
                  name="twitter"
                  placeholder="Instagram URL"
                  value={twitter}
                  onChange={(e) => {
                    setTwitter(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              <Form.Group controlId="dresses" className="formms">
                <Form.Label
                  className="text-black"
                  style={{ fontWeight: "700" }}
                >
                  How many dress to showcase
                </Form.Label>
                <Form.Control
                  style={{ border: "1px solid black", width: "100%" }}
                  type="number"
                  min={1}
                  name="dresses"
                  value={dresses}
                  onChange={(e) => {
                    setDresses(e.target.value);
                  }}
                  className="mb-3 cls"
                  required
                />
              </Form.Group>

              {/* 
          <Form.Group controlId="guests">
            <Form.Label>Number of Guests</Form.Label>
            <Form.Control
              as="select"
              name="guests"
              value={formData.guests}
              onChange={handleChange}
            >
              <option value={1}>1 Guest</option>
              <option value={2}>2 Guests</option>
              <option value={3}>3 Guests</option>
            </Form.Control>
          </Form.Group> */}

              {/* <Form.Group controlId="message">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={3}
              placeholder="Optional: Leave a message for the hotel"
            />
          </Form.Group> */}

              <Button
                variant="primary"
                type="submit"
                style={{ width: "100%" }}
                className="clsm"
              >
                Register
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
