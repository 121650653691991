import PageBuilder from "../../utility/PageBuilder";

const SEOPage = () => {
  const content = [
    "Search Engine Optimization (SEO) is a cornerstone of any successful digital marketing strategy,aimed at enhancing a website's visibility and ranking on search engines like Google. At Atraski, we specialize in both off-page and on-page SEO, ensuring comprehensive optimization to drive organic traffic and improve your online presence. By meticulously optimizing various aspects of your website, we help it appear higher in search results for relevant queries, making it easier for your target audience to find you.",
    "On-page SEO involves optimizing the content and structure of your website. This includes refining keywords, meta tags, headers, and internal links to make your site more appealing to search engine algorithms. By ensuring that your content is relevant, high-quality, and well structured, we enhance your website's usability and search engine friendliness. This not only improves your rankings but also provides a better user experience, which can lead to higher engagement and conversions.",
    "Off-page SEO, on the other hand, focuses on external factors that influence your website's ranking. This includes building high-quality backlinks from reputable websites, social media marketing, and other forms of online promotion. By establishing your website as a credible and authoritative source through strategic link-building and brand mentions, we enhance your site's reputation and visibility across the web. This holistic approach ensures that your website gains the trust of both search engines and users.",
    "At Atraski, our goal is to provide businesses with a competitive edge in the online marketplace through effective SEO strategies. We understand that users tend to trust websites that appear at the top of search results, which is why we are dedicated to helping you achieve those top positions. With our expertise in both on-page and off-page SEO, we ensure that your website is optimized from all angles, driving more organic traffic, building trust and credibility, and ultimately contributing to your business's growth and success.",
  ];

  return <PageBuilder title="Off Page SEO and On Page SEO" content={content} />;
};

export default SEOPage;
