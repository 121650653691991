import { Fragment } from "react";
import { Link } from "react-router-dom";

import WorkGallery from "../components/WorkGallery";
import WorkVideos from "../components/WorkVideos";
import SideNav from "../components/SideNav";
import "./Work.css";

const WorkPage = () => {
  const DMLink = "/services";

  const listItem = [
    { name: "Digital Marketing", link: `${DMLink}/digital-marketing` },
    { name: "Public Relation", link: `${DMLink}/public-relation` },
    {
      name: "Influential Marketing",
      link: `${DMLink}/influential-marketing`,
    },
    { name: "Fashion", link: "/fashion" },
    {
      name: "Intellectual Property",
      link: `${DMLink}/intellectual-property`,
    },
    { name: "Events & Exhibition", link: `${DMLink}/events-exhibition` },
  ];

  return (
    <Fragment>
      <div className="test-work-container">
        <h1>Our Work</h1>
        <div className="test-work">
          <Link to={`${DMLink}/digital-marketing`}>
            <div>
              <h2>Digital Marketings</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/public-relation`}>
            <div>
              <h2>Public Relation</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/influential-marketing`}>
            <div>
              <h2>Influential Marketing</h2>
            </div>
          </Link>
          <Link to={`/fashion`}>
            <div>
              <h2>Fashion</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/intellectual-property`}>
            <div>
              <h2>Intellectual Property</h2>
            </div>
          </Link>
          <Link to={`${DMLink}/events-exhibition`}>
            <div>
              <h2>Events & Exhibition</h2>
            </div>
          </Link>
        </div>
      </div>

      <WorkVideos />

      <WorkGallery />

      <SideNav listItems={listItem} />
    </Fragment>
  );
};

export default WorkPage;
