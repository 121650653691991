import work1 from "../images/DY PATIL1.jpg";
import work2 from "../images/nissan.jpg";
import work3 from "../images/pharmco.jpg";
import work4 from "../images/suez.jpg";
import work5 from "../images/ultrasound.jpg";
import work6 from "../images/weisstechnique.jpg";
import work7 from "../images/jhonny walker.jpg";
import work8 from "../images/medicene.jpg";
import work9 from "../images/nothingcoinbank.jpg";
import work10 from "../images/mi.jpg";
import work11 from "../images/pepe.jpg";
import work12 from "../images/volksvagan.jpg";

import "./workGallery.css";

const WorkGallery = () => {
  return (
    <div style={{ padding: "10px 0 40px 0" }}>
      <h1 style={{ color: "#fc0c8c", fontWeight: "900" }}>Gallery</h1>
      <div className="gallery">
        <div className="hem">
          <img
            src={work1}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            On 6th and 7th May Atraski successfully executed exhibition stall
            design and fabrication for @dpuunitechsociety at Pragati Maidan,
            Delhi organised by @collegedunia . This success goes to our entire
            team who planned and executed the entire project with dedication and
            passion.
          </div>
        </div>

        <div className="hem">
          <img
            src={work2}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            Atraski Events (@atraskievents) is proud to be a part of the launch
            of the new Nissan Magnite for Nissan motor India!
          </div>
        </div>

        <div className="hem">
          <img
            src={work3}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            We are proud to implement exhibition stall for Alkem.
          </div>
        </div>

        <div className="hem">
          <img
            src={work4}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />

          <div className="hello">
            We are proud to implement exhibition stall for Suez.
          </div>
        </div>

        <div className="hem">
          <img
            src={work5}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            Mindray India participated in CME organized by the Indian Society
            for the Study of Pain, Pune Chapter (SSPP) titled “#Ultrasound – An
            Eye of Pain Physicians” on 17th Oct.
          </div>
        </div>

        <div className="hem">
          <img
            src={work6}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />

          <div className="hello">
            Proud to execute book for Weisstechnik at NuGen Mobility Summit
            organised by ICAT.
          </div>
        </div>

        <div className="hem">
          <img
            src={work7}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">Branding for Johnnie Walker</div>
        </div>

        <div className="hem">
          <img
            src={work8}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            LIIT ARCH GATE installation for Wellness Forever
          </div>
        </div>

        <div className="hem">
          <img
            src={work9}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />

          <div className="hello">nothingcoinbank by 5 star</div>
        </div>

        <div className="hem">
          <img
            src={work10}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            Atraski events (@atraskievents) are proud to be part of this event
            and we have organized this conference meet for MI.
          </div>
        </div>

        <div className="hem">
          <img
            src={work11}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            Atraski events (@atraskievents) implemented this mobile showcase van
            for Pepe Jeans!
          </div>
        </div>

        <div className="hem">
          <img
            src={work12}
            style={{
              width: "100%",
              display: "block",
              border: "3px solid black",
            }}
          />
          <div className="hello">
            Atraskievents successfully organised a virtual meeting for
            Volkswagen
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkGallery;
